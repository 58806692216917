import {
  CUSTOMER_TAG_INFO,
  CUSTOMER_TAG_INFO_SUCCESS,
  CUSTOMER_TAG_INFO_FAIL,
} from "./actionTypes";

const initialState = {
  loading: false,
  message: null,
  error: null,
  data: null,
  tagId: null,
};

const customerTagInfo = (state = initialState, action) => {
  switch (action.type) {
    case CUSTOMER_TAG_INFO:
    case CUSTOMER_TAG_INFO_SUCCESS:
    case CUSTOMER_TAG_INFO_FAIL:
      state = {
        ...state,
        ...action.payload,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default customerTagInfo;
