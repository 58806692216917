import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

//i18n
import { withTranslation } from "react-i18next";
import SidebarContent from "./SidebarContent";
import { getPriorityRole } from "../../helpers/getPriorityRole";

const Sidebar = (props) => {
  return (
    <React.Fragment>
      <div className="vertical-menu">
        <div className="h-100">
          <div className="user-wid text-center py-4">
            {props?.currentUser?.data?.avatar ? (
              <div className="user-img">
                <img
                  src={props?.currentUser?.avatar}
                  alt=""
                  className="avatar-lg mx-auto rounded-circle profile"
                />
              </div>
            ) : (
              <div className="mx-auto avatar-lg font-size-20">
                <span className="avatar-title bg-soft-primary text-primary dashboard-icon">
                  <i className="dripicons dripicons-user"></i>
                </span>
              </div>
            )}
            <div className="mt-3">
              <Link to="#" className="text-dark fw-medium font-size-16">
                {`${props?.currentUser?.data?.fname || ""} ${
                  props?.currentUser?.data?.lname || ""
                }`}
              </Link>
              <p className="text-body mt-1 mb-0 font-size-13">
                {getPriorityRole(props?.currentUser?.data?.role)}
              </p>
            </div>
          </div>
          <div data-simplebar className="h-100">
            {props.type !== "condensed" ? (
              <SidebarContent />
            ) : (
              <SidebarContent />
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

Sidebar.propTypes = {
  currentUser: PropTypes.object,
  type: PropTypes.string,
};

const mapStatetoProps = (state) => {
  return {
    layout: state.Layout,
    currentUser: state.currentUser,
  };
};
export default connect(
  mapStatetoProps,
  null
)(withRouter(withTranslation()(Sidebar)));
