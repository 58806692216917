import {
  NEW_AVATAR_UPLOAD,
  NEW_AVATAR_UPLOAD_SUCCESS,
  NEW_AVATAR_UPLOAD_FAIL,
  NEW_AVATAR_UPLOAD_RESET,
} from "./actionTypes";

export const newAvatarUpload = (avatarFile) => {
  return {
    type: NEW_AVATAR_UPLOAD,
    payload: {
      avatarFile,
      loading: true,
      message: null,
      error: null,
    },
  };
};

export const onNewAvatarUploadSuccess = (newAvatar) => {
  return {
    type: NEW_AVATAR_UPLOAD_SUCCESS,
    payload: {
      loading: false,
      error: null,
      message: null,
      newAvatar,
    },
  };
};

export const onNewAvatarUploadFail = (error) => {
  return {
    type: NEW_AVATAR_UPLOAD_FAIL,
    payload: {
      error,
      loading: false,
      message: null,
    },
  };
};

export const onNewAvatarUploadReset = () => {
  return {
    type: NEW_AVATAR_UPLOAD_RESET,
  };
};
