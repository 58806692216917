import {
  CUSTOMER_TAGS,
  CUSTOMER_TAGS_SUCCESS,
  CUSTOMER_TAGS_FAIL,
} from "./actionTypes";

export const customerTags = (userId) => {
  return {
    type: CUSTOMER_TAGS,
    payload: {
      userId,
      loading: true,
    },
  };
};

export const onCustomerTagsSuccess = (data) => {
  return {
    type: CUSTOMER_TAGS_SUCCESS,
    payload: {
      data,
      loading: false,
    },
  };
};

export const onCustomerTagsFail = (error) => {
  return {
    type: CUSTOMER_TAGS_FAIL,
    payload: {
      error,
      loading: false,
    },
  };
};
