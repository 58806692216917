import { takeEvery, put, call } from "redux-saga/effects";

// Login Redux States
import { REMOVE_CUSTOMER_TAG } from "./actionTypes";
import { onRemoveCustomerTagSuccess, onRemoveCustomerTagFail } from "./actions";

import { deleteCustomerTag } from "../../../helpers/tags";

function* removeCustomerTag(action) {
  try {
    const response = yield call(deleteCustomerTag, action?.payload?.tagId);
    if (response?.ok == 1) {
      yield put(onRemoveCustomerTagSuccess(response?.message));
    } else {
      yield put(onRemoveCustomerTagFail(response?.message));
    }
  } catch (error) {
    yield put(onRemoveCustomerTagFail(error?.message || error));
  }
}

function* removeCustomerTagSaga() {
  yield takeEvery(REMOVE_CUSTOMER_TAG, removeCustomerTag);
}

export default removeCustomerTagSaga;
