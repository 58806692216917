import {
  CURRENT_USER,
  CURRENT_USER_SUCCESS,
  CURRENT_USER_FAIL,
} from "./actionTypes";

export const currentUser = () => {
  return {
    type: CURRENT_USER,
    payload: {
      loading: true,
    },
  };
};

export const onCurrUserSuccess = (data) => {
  return {
    type: CURRENT_USER_SUCCESS,
    payload: {
      data,
      loading: false,
    },
  };
};

export const onCurrUserFail = (error) => {
  return {
    type: CURRENT_USER_FAIL,
    payload: {
      error,
      loading: false,
    },
  };
};
