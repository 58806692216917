import { takeEvery, put, call, takeLatest } from "redux-saga/effects";

// Login Redux States
import { CURRENT_USER } from "./actionTypes";
import { onCurrUserSuccess, onCurrUserFail } from "./actions";

import { getCurrentUser } from "../../../helpers/users";

function* currentUser() {
  try {
    const response = yield call(getCurrentUser);
    if (response?.ok == 1) {
      yield put(onCurrUserSuccess(response?.data));
    } else {
      yield put(onCurrUserFail(response?.message || "User info missing"));
    }
  } catch (error) {
    yield put(onCurrUserFail(error?.message || error));
  }
}

function* currentUserSaga() {
  yield takeLatest(CURRENT_USER, currentUser);
}

export default currentUserSaga;
