import { all, fork } from "redux-saga/effects";

//public
import AccountSaga from "./auth/register/saga";
import AuthSaga from "./auth/login/saga";
import ForgetSaga from "./auth/forgetpwd/saga";
import SetPasswordSaga from "./auth/setpassword/saga";
import ProfileSaga from "./auth/profile/saga";
import LayoutSaga from "./layout/saga";
import calendarSaga from "./calendar/saga";
import chatSaga from "./chat/saga";
import tasksSaga from "./tasks/saga";
import contactsSaga from "./contacts/saga";
import currentUserSaga from "./users/currentUser/saga";
import customersSaga from "./users/customers/saga";
import allTagsSaga from "./tags/allTags/saga";
import customerTagsSaga from "./tags/customerTags/saga";
import customerTagInfoSaga from "./tags/customerTagInfo/saga";
import newCustomerTagSaga from "./tags/newCustomerTag/saga";
import modifyCustomerTagSaga from "./tags/modifyCustomerTag/saga";
import removeCustomerTagSaga from "./tags/removeCustomerTag/saga";
import newAvatarUploadSaga from "./uploads/newAvatarUpload/saga";
import customerTagsStatsSaga from "./tags/customerTagsStats/saga";

export default function* rootSaga() {
  yield all([
    //public
    AccountSaga(),
    fork(AuthSaga),
    fork(SetPasswordSaga),
    ProfileSaga(),
    ForgetSaga(),
    fork(LayoutSaga),
    fork(calendarSaga),
    fork(chatSaga),
    fork(tasksSaga),
    fork(contactsSaga),
    fork(currentUserSaga),
    fork(customersSaga),
    fork(allTagsSaga),
    fork(customerTagsSaga),
    fork(customerTagInfoSaga),
    fork(newCustomerTagSaga),
    fork(modifyCustomerTagSaga),
    fork(removeCustomerTagSaga),
    fork(newAvatarUploadSaga),
    fork(customerTagsStatsSaga),
  ]);
}
