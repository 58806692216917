import React, { useState } from "react";
import {
  Row,
  Col,
  CardBody,
  Card,
  Form,
  InputGroup,
  UncontrolledAlert as Alert,
  Button,
  Input,
  Label,
  Spinner,
} from "reactstrap";
import "react-datepicker/dist/react-datepicker.css";
import Flatpickr from "react-flatpickr";
import {
  customerTagInfo,
  modifyCustomerTag,
  newAvatarUpload,
  newCustomerTag,
  onNewAvatarUploadReset,
} from "../../store/actions";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  FIELD_TYPES,
  FIELD_TYPE_MAIN,
  getFieldTypeLabel,
} from "../../constants/fieldTypes";
import { useEffect } from "react";
import { useRef } from "react";

const FieldPhone = ({ item, onRemove, onChange, showLabel = true }) => {
  return (
    <div className="inner-repeater tag-field-phone">
      {showLabel && <Label className="col-form-Label">Phone:</Label>}
      <table
        style={{
          width: "100%",
        }}
      >
        <tbody>
          <tr>
            <td>
              <div className="row mb-2">
                <div className="col-md-2">
                  <select
                    className="form-control"
                    onChange={(evt) => onChange("type", evt.target.value)}
                    value={item.type}
                  >
                    {FIELD_TYPES.phone.map((val) => (
                      <option value={val}>{val}</option>
                    ))}
                  </select>
                </div>
                <div className="col-lg-9">
                  <Input
                    type="text"
                    className="inner form-control"
                    placeholder="Enter your phone no..."
                    onChange={(evt) => {
                      onChange("value", evt.target.value);
                    }}
                    value={item.value}
                  />
                </div>
                <div className="col-lg-1">
                  <Button
                    onClick={onRemove}
                    color="danger"
                    className="minusBtn"
                    style={{
                      width: "100%",
                    }}
                  >
                    <i className="mdi mdi-close"></i>
                  </Button>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

const FieldEmail = ({ item, onRemove, onChange, showLabel = true }) => {
  return (
    <div className="inner-repeater tag-field-email">
      {showLabel && <Label className="col-form-Label">Email Address:</Label>}
      <table
        style={{
          width: "100%",
        }}
      >
        <tbody>
          <tr>
            <td>
              <div className="row mb-2">
                <div className="col-md-2">
                  <select
                    className="form-control"
                    onChange={(evt) => onChange("type", evt.target.value)}
                    value={item.type}
                  >
                    {FIELD_TYPES.email.map((val) => (
                      <option value={val}>{val}</option>
                    ))}
                  </select>
                </div>
                <div className="col-lg-9">
                  <Input
                    type="email"
                    className="inner form-control"
                    placeholder="Enter your email address..."
                    onChange={(evt) => onChange("value", evt.target.value)}
                    value={item.value}
                  />
                </div>
                <div className="col-lg-1">
                  <Button
                    onClick={onRemove}
                    color="danger"
                    className="minusBtn"
                    style={{
                      width: "100%",
                    }}
                  >
                    <i className="mdi mdi-close"></i>
                  </Button>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

const FieldWebsite = ({ item, onRemove, onChange, showLabel = true }) => {
  return (
    <div className="inner-repeater tag-field-website">
      {showLabel && <Label className="col-form-Label">Website/Link:</Label>}
      <table
        style={{
          width: "100%",
        }}
      >
        <tbody>
          <tr>
            <td>
              <div className="row mb-2">
                <div className="col-md-2">
                  <select
                    className="form-control"
                    onChange={(evt) => onChange("type", evt.target.value)}
                    value={item.type}
                  >
                    {FIELD_TYPES.website.map((val) => (
                      <option value={val}>{val}</option>
                    ))}
                  </select>
                </div>
                <div className="col-lg-9">
                  <Input
                    type="link"
                    className="inner form-control"
                    placeholder="Enter your website/link..."
                    onChange={(evt) => onChange("value", evt.target.value)}
                    value={item.value}
                  />
                </div>
                <div className="col-lg-1">
                  <Button
                    onClick={onRemove}
                    color="danger"
                    className="minusBtn"
                    style={{
                      width: "100%",
                    }}
                  >
                    <i className="mdi mdi-close"></i>
                  </Button>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

const FieldAddress = ({ item, onRemove, onChange, showLabel = true }) => {
  return (
    <div className="inner-repeater tag-field-address">
      {showLabel && <Label className="col-form-Label">Address:</Label>}
      <table
        style={{
          width: "100%",
        }}
      >
        <tbody>
          <tr>
            <td>
              <div className="row mb-2">
                <div className="col-md-2">
                  <select
                    className="form-control"
                    onChange={(evt) => onChange("type", evt.target.value)}
                    value={item.type}
                  >
                    {FIELD_TYPES.address.map((val) => (
                      <option value={val}>{val}</option>
                    ))}
                  </select>
                </div>
                <div className="col-lg-9">
                  <Input
                    type="address"
                    className="inner form-control"
                    placeholder="Enter your address..."
                    onChange={(evt) => onChange("value", evt.target.value)}
                    value={item.value}
                  />
                </div>
                <div className="col-lg-1">
                  <Button
                    onClick={onRemove}
                    color="danger"
                    className="minusBtn"
                    style={{
                      width: "100%",
                    }}
                  >
                    <i className="mdi mdi-close"></i>
                  </Button>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

const VCard = ({
  fName = "",
  lName = "",
  position = "",
  avatar = "",
  onUploadAvatar,
  loading = false,
}) => (
  <div
    className="card-frame"
    style={{
      backgroundImage: `url(${process.env.PUBLIC_URL}/images/verticalImigongo.png)`,
    }}
  >
    <img
      src={`${process.env.PUBLIC_URL}/images/nfcIcon.png`}
      alt="logo"
      className="img-full nfc-icon-card img-fluid"
    />
    <div>
      <img
        src={`${process.env.PUBLIC_URL}/images/compay-logo-white.png`}
        alt="patterns"
        className="img-full nfc-icon-card img-fluid"
      />
    </div>
    <div className="name-title text-center">
      <div className="row">
        <div className="col-12 pb-2 d-dlex align-items-center justify-content-center">
          {avatar ? (
            <div className="profile-img tag-avatar" onClick={onUploadAvatar}>
              <img src={avatar} alt="profile" className="icon-txt" />
            </div>
          ) : (
            <i
              onClick={onUploadAvatar}
              className="fa fa-user-circle tag-avatar"
              style={{ fontSize: 90 }}
            />
          )}
          {loading && (
            <Spinner
              size="small"
              color="success"
              style={{
                position: "absolute",
                top: 30,
                right: "calc(50% - 18px)",
              }}
            />
          )}
        </div>
        <div className="col-12">
          {fName || lName ? <h3>{`${fName || ""} ${lName || ""}`}</h3> : ""}
        </div>
        <div className="col-12">{position && <h5>{position || ""}</h5>}</div>
      </div>
    </div>
    <img
      src={`${process.env.PUBLIC_URL}/images/logo-white.png`}
      alt="logo"
      className="img-full logo-card img-fluid"
    />
  </div>
);

const DEAFULT_TAG = {
  phones: [
    {
      type: FIELD_TYPE_MAIN,
      value: "",
    },
  ],
  emails: [
    {
      type: FIELD_TYPE_MAIN,
      value: "",
    },
  ],
  websites: [
    {
      type: FIELD_TYPE_MAIN,
      value: "",
    },
  ],
  addresses: [
    {
      type: FIELD_TYPE_MAIN,
      value: "",
    },
  ],
};

const UserTagInfo = (props) => {
  const [title, setTitle] = useState("");
  const [company, setCompany] = useState("");
  const [lName, setLName] = useState("");
  const [fName, setFName] = useState("");
  const [position, setPosition] = useState("");
  const [dob, setDob] = useState("");
  const [notes, setNotes] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [avatar, setAvatar] = useState("");
  const [tag, setTag] = useState();
  const tagAvatarInputRef = useRef();

  const onSaveTagInfo = (evt) => {
    evt?.preventDefault?.();
    const formattedDob = (Array.isArray(dob) ? dob?.[0] : dob) || "";
    if (props?.isNew) {
      const newTag = {
        tag: {
          ...tag,
          avatar: avatar,
          title: title,
          company: company,
          lname: lName,
          fname: fName,
          position: position,
          dob: formattedDob,
          notes: notes,
          mine: props?.isMine ? 1 : 0,
        },
      };
      props?.createTag?.(newTag);
    } else if (!props?.isNew && props.match.params.tagId) {
      const update = {
        tag: {
          ...tag,
          avatar: avatar,
          title: title,
          company: company,
          lname: lName,
          fname: fName,
          position: position,
          dob: formattedDob,
          notes: notes,
          mine: props?.isMine ? 1 : 0,
        },
      };
      props?.editTagInfo?.(props.match.params.tagId, update);
    }
  };

  const onTagFormChange = (evt) => {
    if (evt && evt?.target?.name) {
      evt?.preventDefault?.();
      const newState = {
        ...tag,
        [evt?.target?.name]: evt?.target?.value || "",
      };
      setTag(newState);
    }
  };

  const onAddNewPhone = () => {
    const newState = {
      ...tag,
      phones: [
        ...(tag?.phones || []),
        {
          type: FIELD_TYPES.phone[0],
          value: "",
        },
      ],
    };
    setTag(newState);
  };

  const onAddNewEmail = () => {
    setTag((prevState) => ({
      ...prevState,
      emails: [
        ...(prevState?.emails || []),
        {
          type: FIELD_TYPES.email[0],
          value: "",
        },
      ],
    }));
  };

  const onAddNewWebsite = () => {
    setTag((prevState) => ({
      ...prevState,
      websites: [
        ...(prevState?.websites || []),
        {
          type: FIELD_TYPES.website[0],
          value: "",
        },
      ],
    }));
  };

  const onAddNewAddress = () => {
    setTag((prevState) => ({
      ...prevState,
      addresses: [
        ...(prevState?.addresses || []),
        {
          type: FIELD_TYPES.address[0],
          value: "",
        },
      ],
    }));
  };

  const onRemovePhone = (tIdx) => {
    setTag((prevState) => ({
      ...prevState,
      phones: prevState?.phones?.filter((_item, idx) => idx != tIdx),
    }));
  };

  const onRemoveEmail = (tIdx) => {
    setTag((prevState) => ({
      ...prevState,
      emails: prevState?.emails?.filter((_item, idx) => idx != tIdx),
    }));
  };

  const onRemoveWebsite = (tIdx) => {
    setTag((prevState) => ({
      ...prevState,
      websites: prevState?.websites?.filter((_item, idx) => idx != tIdx),
    }));
  };

  const onRemoveAddress = (tIdx) => {
    setTag((prevState) => ({
      ...prevState,
      addresses: prevState?.addresses?.filter((_item, idx) => idx != tIdx),
    }));
  };

  const onPhoneChange = (type, newValue, idx) => {
    const newTag = { ...tag };
    if (!newTag?.phones?.[idx]) return;
    if (type == "type") {
      newTag.phones[idx].type = newValue;
    } else if (type == "value") {
      newTag.phones[idx].value = newValue;
    }
    setTag(newTag);
  };

  const onEmailChange = (type, newValue, idx) => {
    const newTag = { ...tag };
    if (!newTag?.emails?.[idx]) return;
    if (type == "type") {
      newTag.emails[idx].type = newValue;
    } else if (type == "value") {
      newTag.emails[idx].value = newValue;
    }
    setTag(newTag);
  };

  const onWebsiteChange = (type, newValue, idx) => {
    const newTag = { ...tag };
    if (!newTag?.websites?.[idx]) return;
    if (type == "type") {
      newTag.websites[idx].type = newValue;
    } else if (type == "value") {
      newTag.websites[idx].value = newValue;
    }
    setTag(newTag);
  };

  const onAddressChange = (type, newValue, idx) => {
    const newTag = { ...tag };
    if (!newTag?.addresses?.[idx]) return;
    if (type == "type") {
      newTag.addresses[idx].type = newValue;
    } else if (type == "value") {
      newTag.addresses[idx].value = newValue;
    }
    setTag(newTag);
  };

  const onUploadAvatar = () => {
    const input = tagAvatarInputRef.current;
    input?.click?.();
  };

  const onAvatarInputChange = async (evt) => {
    try {
      const file = evt.target.files?.[0];
      if (file) {
        // uncomment the following line to store the avatar as a URL
        props?.uploadAvatar?.(file);
        // store avatar in base64
        // const base64Img = await convertImgFileToBase64(file);
        // setAvatar(base64Img);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onResetTag = () => {
    setTag(undefined);
    setTitle("");
    setCompany("");
    setFName("");
    setLName("");
    setPosition("");
    setNotes("");
    setDob("");
    setAvatar("");
  };

  const onScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    if (!props?.isNew && props?.customerTagInfo?.data) {
      setTag(props.customerTagInfo?.data?.tag_info);
      setTitle(props.customerTagInfo.data?.tag_info?.title || "");
      setAvatar(props.customerTagInfo.data?.tag_info?.avatar || "");
      setCompany(props.customerTagInfo.data?.tag_info?.company || "");
      setFName(props.customerTagInfo.data?.tag_info?.fname || "");
      setLName(props.customerTagInfo.data?.tag_info?.lname || "");
      setPosition(props.customerTagInfo.data?.tag_info?.position || "");
      setNotes(props.customerTagInfo.data?.tag_info?.notes || "");
      setDob(props.customerTagInfo.data?.tag_info?.dob || "");
    }
    return () => {
      onResetTag();
    };
  }, [props?.isNew, props?.customerTagInfo?.data]);

  useEffect(() => {
    onResetTag();
    if (!props?.isNew && props.match.params.tagId) {
      props.getTagInfo(props.match.params.tagId);
    }
  }, [props?.isNew, props.match.params.tagId]);

  useEffect(() => {
    onScrollToTop();
    setMessage(props.modifyCustomerTag?.message);

    return () => {
      setMessage(undefined);
    };
  }, [props.modifyCustomerTag?.message]);

  useEffect(() => {
    onScrollToTop();
    setError(props.modifyCustomerTag?.error);

    return () => {
      setError(undefined);
    };
  }, [props.modifyCustomerTag?.error]);

  useEffect(() => {
    if (props.newCustomerTag?.message) {
      onResetTag();
    }
    onScrollToTop();
    setMessage(props.newCustomerTag?.message);

    return () => {
      setMessage(undefined);
    };
  }, [props.newCustomerTag?.message]);

  useEffect(() => {
    onScrollToTop();
    setError(props.newCustomerTag?.error);

    return () => {
      setError(undefined);
    };
  }, [props.newCustomerTag?.error]);

  useEffect(() => {
    if (props.newAvatarUpload?.newAvatar) {
      setAvatar(props.newAvatarUpload?.newAvatar);
    }

    return () => {
      props.uploadAvatarReset?.();
    };
  }, [props.newAvatarUpload?.newAvatar]);

  return (
    <div className="page-content">
      <Row>
        <div className="col-12">
          <div className="page-title-box d-flex align-items-center justify-content-between">
            <h4 className="page-title mb-0 font-size-18">
              <i className="fa fa-back"></i> User Tag
            </h4>
            <div className="page-title-right">
              <ol className="breadcrumb m-0">
                <li className="breadcrumb-item active">
                  Welcome to Bizcotap Dashboard
                </li>
              </ol>
            </div>
          </div>
        </div>
      </Row>
      <Row>
        <Col lg={3}>
          <div className="col-lg-12 cardProfile">
            <input
              type="file"
              accept="image/png, image/svg, image/jpeg, image/jpg"
              className="d-none"
              id="tag_avatar_file"
              onChange={onAvatarInputChange}
              ref={tagAvatarInputRef}
              multiple={false}
              disabled={props?.newAvatarUpload?.loading}
            />
            <div className="wrapper">
              <VCard
                fName={fName}
                lName={lName}
                position={position}
                avatar={avatar}
                onUploadAvatar={onUploadAvatar}
                loading={props?.newAvatarUpload?.loading}
              />
              <div className="card-content">
                <div className="saveButton"></div>
                <ul className="detailsList">
                  {company && (
                    <li>
                      <p>Company</p>
                      <p>{company || ""}</p>
                    </li>
                  )}
                  {tag?.phones?.map((item) =>
                    item?.value ? (
                      <li>
                        <p className="text-capitalize">
                          Phone ({getFieldTypeLabel(item.type) || ""})
                        </p>
                        <p>
                          <a href={`tel:${item?.value || ""}`}>
                            {item?.value || ""}
                          </a>
                        </p>
                      </li>
                    ) : null
                  )}
                  {tag?.emails?.map((item) =>
                    item?.value ? (
                      <li>
                        <p className="text-capitalize">
                          Email ({getFieldTypeLabel(item.type) || ""})
                        </p>
                        <p>
                          <a href={`mailto:${item?.value || ""}`}>
                            {item?.value || ""}
                          </a>
                        </p>
                      </li>
                    ) : null
                  )}
                  {tag?.websites?.map((item) =>
                    item?.value ? (
                      <li>
                        <p className="text-capitalize">
                          Website/Link ({getFieldTypeLabel(item.type) || ""})
                        </p>
                        <p>{item?.value || ""}</p>
                      </li>
                    ) : null
                  )}
                  {tag?.addresses?.map((item) =>
                    item?.value ? (
                      <li>
                        <p className="text-capitalize">
                          Address ({getFieldTypeLabel(item.type) || ""})
                        </p>
                        <p>{item?.value || ""}</p>
                      </li>
                    ) : null
                  )}
                </ul>
              </div>
            </div>
          </div>
        </Col>
        <Col lg={9}>
          <Card>
            <CardBody>
              <>
                {error && <Alert color="warning">{error}</Alert>}
                {message && <Alert color="success">{message}</Alert>}
              </>
              <Form onSubmit={onSaveTagInfo} onChange={onTagFormChange}>
                <div className="row mb-2">
                  <div className="col-lg-2">
                    <div className="row mb-3">
                      <div className="col-lg-12">
                        <Label className="col-form-Label">Title</Label>
                        <Input
                          name="title"
                          className="form-control"
                          type="text"
                          value={title}
                          onChange={(evt) => {
                            setTitle(evt.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-5">
                    <div className="row mb-3">
                      <div className="col-lg-12">
                        <Label className="col-form-Label">First Name</Label>
                        <Input
                          name="fname"
                          className="form-control"
                          type="text"
                          required
                          value={fName}
                          onChange={(evt) => {
                            setFName(evt.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-5">
                    <div className="row mb-3">
                      <div className="col-lg-12">
                        <Label className="col-lg-3 col-form-Label">
                          Last Name
                        </Label>
                        <Input
                          name="lname"
                          className="form-control"
                          type="text"
                          required
                          value={lName}
                          onChange={(evt) => {
                            setLName(evt.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-lg-4">
                    <div className="row mb-3">
                      <div className="col-lg-12">
                        <Label className="col-form-Label">Company</Label>
                        <Input
                          name="company"
                          className="form-control"
                          type="text"
                          required
                          value={company}
                          onChange={(evt) => {
                            setCompany(evt.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="row mb-3">
                      <div className="col-lg-12">
                        <Label className="col-form-Label">Position/title</Label>
                        <Input
                          name="position"
                          className="form-control"
                          type="text"
                          required
                          value={position}
                          onChange={(evt) => {
                            setPosition(evt.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="row mb-3">
                      <div className="col-lg-12">
                        <Label className="col-form-Label">
                          Date of Birth (Optional)
                        </Label>
                        <InputGroup>
                          <Flatpickr
                            className="form-control d-block"
                            placeholder="DD-MM-YYYY"
                            name="dob"
                            options={{
                              altInput: true,
                              altFormat: "d-m-Y",
                              dateFormat: "d-m-Y",
                            }}
                            value={dob}
                            onChange={(value) => {
                              setDob(value);
                            }}
                          />
                        </InputGroup>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-lg-12">
                    {tag?.phones?.map((item, idx) => (
                      <FieldPhone
                        item={item}
                        onRemove={() => onRemovePhone(idx)}
                        onChange={(t, v) => onPhoneChange(t, v, idx)}
                        showLabel={idx == 0}
                      />
                    ))}
                    <Button
                      onClick={onAddNewPhone}
                      color="success"
                      className="addBtn"
                    >
                      <i className="mdi mdi-plus"></i> Add Phone
                    </Button>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-lg-12">
                    {tag?.emails?.map((item, idx) => (
                      <FieldEmail
                        item={item}
                        onRemove={() => onRemoveEmail(idx)}
                        onChange={(t, v) => onEmailChange(t, v, idx)}
                        showLabel={idx == 0}
                      />
                    ))}
                    <Button
                      onClick={onAddNewEmail}
                      color="success"
                      className="addBtn"
                    >
                      <i className="mdi mdi-plus"></i> Add Email
                    </Button>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-lg-12">
                    {tag?.websites?.map((item, idx) => (
                      <FieldWebsite
                        item={item}
                        onRemove={() => onRemoveWebsite(idx)}
                        onChange={(t, v) => onWebsiteChange(t, v, idx)}
                        showLabel={idx == 0}
                      />
                    ))}
                    <Button
                      onClick={onAddNewWebsite}
                      color="success"
                      className="addBtn"
                    >
                      <i className="mdi mdi-plus"></i> Add Website
                    </Button>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-lg-12">
                    {tag?.addresses?.map((item, idx) => (
                      <FieldAddress
                        item={item}
                        onRemove={() => onRemoveAddress(idx)}
                        onChange={(t, v) => onAddressChange(t, v, idx)}
                        showLabel={idx == 0}
                      />
                    ))}
                    <Button
                      onClick={onAddNewAddress}
                      color="success"
                      className="addBtn"
                    >
                      <i className="mdi mdi-plus"></i> Add Address
                    </Button>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="row mb-3">
                      <div className="col-lg-12">
                        <Label
                          htmlFor="notes"
                          className="col-form-Label text-left"
                        >
                          Notes
                        </Label>
                        <textarea
                          id="notes"
                          name="notes"
                          rows="4"
                          className="form-control"
                          value={notes}
                          onChange={(evt) => {
                            setNotes(evt.target.value);
                          }}
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
                <button
                  type="submit"
                  className="btn btn-success waves-effect waves-light float-end"
                  disabled={
                    props?.modifyCustomerTag?.loading ||
                    props?.newCustomerTag?.loading
                  }
                >
                  <i className="bx bx-check-double font-size-16 align-middle me-2"></i>{" "}
                  Save Card Information
                </button>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

UserTagInfo.propTypes = {
  layout: PropTypes.any,
  isNew: PropTypes.bool,
  isMine: PropTypes.bool,
  currentUser: PropTypes.object,
  customerTagInfo: PropTypes.object,
  getTagInfo: PropTypes.func,
  editTagInfo: PropTypes.func,
  createTag: PropTypes.func,
  uploadAvatar: PropTypes.func,
  uploadAvatarReset: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    layout: state.Layout,
    currentUser: state.currentUser,
    customerTagInfo: state.customerTagInfo,
    modifyCustomerTag: state.modifyCustomerTag,
    newCustomerTag: state.newCustomerTag,
    newAvatarUpload: state.newAvatarUpload,
  };
};

export default connect(mapStateToProps, {
  getTagInfo: customerTagInfo,
  editTagInfo: modifyCustomerTag,
  createTag: newCustomerTag,
  uploadAvatar: newAvatarUpload,
  uploadAvatarReset: onNewAvatarUploadReset,
})(UserTagInfo);
