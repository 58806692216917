import axios from "axios";
import { LS_FIELD_JWT } from "../constants/ls";

const logout = () => {
  localStorage.removeItem(LS_FIELD_JWT);
};

const axiosApi = () => {
  //pass new generated access token here
  const token = localStorage.getItem(LS_FIELD_JWT);
  //apply base url for axios
  const { REACT_APP_API_URL } = process.env;
  const ax = axios.create({
    baseURL: REACT_APP_API_URL,
  });
  if (token) {
    ax.defaults.headers = {
      Authorization: `Bearer ${token}`,
    };
  }
  return ax;
};

const handleAxiosError = (error) => {
  if (error && error?.response?.status == 401) {
    logout();
    return;
  }
  throw error;
};

export async function get(url, config = {}) {
  return await axiosApi()
    .get(url, { ...config })
    .then((response) => response.data)
    .catch(handleAxiosError);
}

export async function post(url, data = null, config = {}) {
  return axiosApi()
    .post(url, data, config)
    .then((response) => {return response.data;})
    .catch(handleAxiosError);
}

export async function put(url, data, config = {}) {
  return axiosApi()
    .put(url, { ...data }, { ...config })
    .then((response) => response.data)
    .catch(handleAxiosError);
}

export async function del(url, config = {}) {
  return await axiosApi()
    .delete(url, { ...config })
    .then((response) => response.data)
    .catch(handleAxiosError);
}
