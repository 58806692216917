import { ALL_TAGS, ALL_TAGS_SUCCESS, ALL_TAGS_FAIL } from "./actionTypes";

const initialState = {
  loading: false,
  error: null,
  data: null,
  filter: null,
};

const allTags = (state = initialState, action) => {
  switch (action.type) {
    case ALL_TAGS:
    case ALL_TAGS_SUCCESS:
    case ALL_TAGS_FAIL:
      state = {
        ...state,
        ...action.payload,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default allTags;
