import { TIMELINE_WEEKLY } from "../../../constants/timelines";
import {
  CUSTOMER_TAGS_STATS,
  CUSTOMER_TAGS_STATS_SUCCESS,
  CUSTOMER_TAGS_STATS_FAIL,
} from "./actionTypes";

export const customerTagsStats = (timeline = TIMELINE_WEEKLY) => {
  return {
    type: CUSTOMER_TAGS_STATS,
    payload: {
      timeline,
      loading: true,
    },
  };
};

export const onCustomerTagsStatsSuccess = (data) => {
  return {
    type: CUSTOMER_TAGS_STATS_SUCCESS,
    payload: {
      data,
      loading: false,
    },
  };
};

export const onCustomerTagsStatsFail = (error) => {
  return {
    type: CUSTOMER_TAGS_STATS_FAIL,
    payload: {
      error,
      loading: false,
    },
  };
};
