import {
  SET_PASSWORD,
  SET_PASSWORD_SUCCESS,
  SET_PASSWORD_ERROR,
} from "./actionTypes";

const initialState = {
  error: "",
  loading: null,
};

const setPassword = (state = initialState, action) => {
  switch (action.type) {
    case SET_PASSWORD:
      state = {
        ...state,
        error: "",
        loading: true,
      };
      break;
    case SET_PASSWORD_SUCCESS:
      state = {
        ...state,
        user: action.payload,
        loading: false,
      };
      break;
    case SET_PASSWORD_ERROR:
      state = { ...state, error: action.payload, loading: false };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default setPassword;
