import { takeEvery, put, call, takeLatest } from "redux-saga/effects";

// Login Redux States
import { ALL_TAGS } from "./actionTypes";
import { onAllTagsSuccess, onAllTagsFail } from "./actions";

import { getAllTags } from "../../../helpers/tags";

function* allTags(action) {
  try {
    const response = yield call(getAllTags, action?.payload?.filter);
    if (response?.ok == 1) {
      yield put(onAllTagsSuccess(response?.data));
    } else {
      yield put(onAllTagsFail(response?.message));
    }
  } catch (error) {
    console.error(error);
    yield put(onAllTagsFail(error?.message || error));
  }
}

function* allTagsSaga() {
  yield takeLatest(ALL_TAGS, allTags);
}

export default allTagsSaga;
