import { takeEvery, put, call } from "redux-saga/effects";

// Login Redux States
import { CUSTOMERS } from "./actionTypes";
import { onCustomersSuccess, onCustomersFail } from "./actions";

import { getCustomers } from "../../../helpers/users";

function* customers() {
  try {
    const response = yield call(getCustomers);
    if (response?.ok == 1) {
      yield put(onCustomersSuccess(response?.data));
    } else {
      yield put(onCustomersFail(response?.message));
    }
  } catch (error) {
    yield put(onCustomersFail(error?.message || error));
  }
}

function* customersSaga() {
  yield takeEvery(CUSTOMERS, customers);
}

export default customersSaga;
