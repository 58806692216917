import { takeEvery, put, call } from "redux-saga/effects";

// Login Redux States
import { NEW_CUSTOMER_TAG } from "./actionTypes";
import { onNewCustomerTagSuccess, onNewCustomerTagFail } from "./actions";

import { createCustomerTag } from "../../../helpers/tags";

function* newCustomerTag(action) {
  try {
    const response = yield call(() =>
      createCustomerTag(action?.payload?.newTag)
    );
    if (response?.ok == 1) {
      yield put(onNewCustomerTagSuccess(response?.message));
    } else {
      yield put(onNewCustomerTagFail(response?.message));
    }
  } catch (error) {
    yield put(onNewCustomerTagFail(error?.message || error));
  }
}

function* newCustomerTagSaga() {
  yield takeEvery(NEW_CUSTOMER_TAG, newCustomerTag);
}

export default newCustomerTagSaga;
