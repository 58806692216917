import { call, put, takeLeading } from "redux-saga/effects";

import { setPasswordErrorAC, setPasswordSuccessAC } from "./actions";

import { onCurrUserSuccess } from "../../users/currentUser/actions";

import { postSetPassword } from "../../../helpers/auth";
import { LS_FIELD_JWT } from "../../../constants/ls";
import { SET_PASSWORD } from "./actionTypes";

function* setUserPassword({ payload: { user, history } }) {
  try {
    const response = yield call(postSetPassword, {
      NewPassword: user.password,
    });

    if (response?.ok === 1) {
      localStorage.setItem(LS_FIELD_JWT, response?.data?.token);
      yield put(setPasswordSuccessAC(response?.data?.user));
      yield put(onCurrUserSuccess(response?.data?.user));
      history?.push?.("/dashboard");
      history?.go?.();
    } else {
      yield put(
        setPasswordErrorAC(response?.message || "Change Password failed")
      );
    }
  } catch (error) {
    console.log("error", error.message);

    yield put(setPasswordErrorAC(error?.message || error));
  }
}

function* setPasswordSaga() {
  yield takeLeading(SET_PASSWORD, setUserPassword);
}

export default setPasswordSaga;
