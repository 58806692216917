export const FIELD_TYPE_MAIN = "PREF";
export const FIELD_TYPE_WORK = "WORK";
export const FIELD_TYPE_HOME = "HOME";
export const FIELD_TYPE_CELL = "CELL";
export const FIELD_TYPE_FAX = "FAX";
export const FIELD_TYPE_MAILING = "MAILING";
export const FIELD_TYPE_OTHER = "OTHER";
export const FIELD_TYPES = {
  phone: [
    FIELD_TYPE_MAIN,
    FIELD_TYPE_WORK,
    FIELD_TYPE_HOME,
    FIELD_TYPE_CELL,
    FIELD_TYPE_FAX,
    FIELD_TYPE_OTHER,
  ],
  email: [FIELD_TYPE_HOME, FIELD_TYPE_WORK, FIELD_TYPE_OTHER],
  website: [FIELD_TYPE_HOME, FIELD_TYPE_WORK, FIELD_TYPE_OTHER],
  address: [
    FIELD_TYPE_HOME,
    FIELD_TYPE_WORK,
    FIELD_TYPE_MAILING,
    FIELD_TYPE_OTHER,
  ],
};

export const getFieldTypeLabel = (typeValue = FIELD_TYPE_OTHER) => {
  let label = "Other";
  switch (typeValue) {
    case FIELD_TYPE_MAIN:
      label = "Main";
      break;
    case FIELD_TYPE_WORK:
      label = "Work";
      break;
    case FIELD_TYPE_HOME:
      label = "Home";
      break;
    case FIELD_TYPE_CELL:
      label = "Cell";
      break;
    case FIELD_TYPE_FAX:
      label = "Fax";
      break;
    case FIELD_TYPE_MAILING:
      label = "Mailing";
      break;
    case FIELD_TYPE_OTHER:
      label = "Other";
      break;

    default:
      label = "Other";
      break;
  }
  return label;
};
