import {
  NEW_AVATAR_UPLOAD,
  NEW_AVATAR_UPLOAD_SUCCESS,
  NEW_AVATAR_UPLOAD_FAIL,
  NEW_AVATAR_UPLOAD_RESET,
} from "./actionTypes";

const initialState = {
  loading: false,
  message: null,
  error: null,
  data: null,
  avatarFile: null,
  newAvatar: null,
};

const newAvatarUpload = (state = initialState, action) => {
  switch (action.type) {
    case NEW_AVATAR_UPLOAD:
    case NEW_AVATAR_UPLOAD_SUCCESS:
    case NEW_AVATAR_UPLOAD_FAIL:
      state = {
        ...state,
        ...action.payload,
      };
      break;
    case NEW_AVATAR_UPLOAD_RESET:
      state = {
        ...initialState,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default newAvatarUpload;
