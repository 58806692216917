import {
  CUSTOMER_TAG_INFO,
  CUSTOMER_TAG_INFO_SUCCESS,
  CUSTOMER_TAG_INFO_FAIL,
} from "./actionTypes";

export const customerTagInfo = (tagId) => {
  return {
    type: CUSTOMER_TAG_INFO,
    payload: {
      tagId,
      loading: true,
    },
  };
};

export const onCustomerTagInfoSuccess = (data) => {
  return {
    type: CUSTOMER_TAG_INFO_SUCCESS,
    payload: {
      data,
      loading: false,
    },
  };
};

export const onCustomerTagInfoFail = (error) => {
  return {
    type: CUSTOMER_TAG_INFO_FAIL,
    payload: {
      error,
      loading: false,
    },
  };
};
