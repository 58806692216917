import React, { useEffect, useRef, useState } from "react";
import {
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Input,
  Spinner,
  Label,
} from "reactstrap";
import "react-datepicker/dist/react-datepicker.css";
import "../Tables/datatables.scss";
import Breadcrumb from "../../components/Common/Breadcrumb";
import Avatar from "react-avatar";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  newAvatarUpload,
  onNewAvatarUploadReset,
  setPasswordAC,
} from "../../store/actions";
import {
  AvFeedback,
  AvForm,
  AvGroup,
  AvInput,
} from "availity-reactstrap-validation";

// react icons
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

const Settings = (props) => {
  const [avatar, setAvatar] = useState("");
  const [email, setEmail] = useState("");

  // show and hide password state
  const [showPassword, setShowPassword] = useState(false);

  const tagAvatarInputRef = useRef();

  const onUploadAvatar = () => {
    const input = tagAvatarInputRef.current;
    input?.click?.();
  };

  const onAvatarInputChange = async (evt) => {
    try {
      const file = evt.target.files?.[0];
      if (file) {
        props?.uploadAvatar?.(file);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // handle submit valid data function
  function handleValidSubmit(_, values) {
    console.log("values", values);
    // console.log("props", props.history);
    props.setPasswordAC(values, props.history);
  }

  // Handle Show and Hide Password
  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    if (props?.currentUser?.data?.email) {
      setEmail(props?.currentUser?.data?.email);
    }
    if (props?.currentUser?.data?.avatar) {
      setAvatar(props?.currentUser?.data?.avatar);
    }
  }, [props?.currentUser?.data]);

  useEffect(() => {
    if (props.newAvatarUpload?.newAvatar) {
      setAvatar(props.newAvatarUpload?.newAvatar);
    }

    return () => {
      props.uploadAvatarReset?.();
    };
  }, [props.newAvatarUpload?.newAvatar]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Breadcrumb title="Bizcotap" breadcrumbItem="Profile" />
        <Row>
          <Col lg="12">
            {props.error && props.error ? (
              <Alert color="danger">{props.error}</Alert>
            ) : null}
            {props.success && props.success ? (
              <Alert color="success">{props.success}</Alert>
            ) : null}
            <Card>
              <CardBody>
                <div className="d-flex">
                  <div className="d-dlex align-items-center justify-content-center">
                    <Avatar
                      src={avatar}
                      name={`${props?.currentUser?.data?.fname || ""} ${
                        props?.currentUser?.data?.lname || ""
                      }`}
                      size={90}
                      color="#354c60"
                      round
                      className="profile-avatar"
                      onClick={onUploadAvatar}
                    />
                    {props?.newAvatarUpload?.loading && (
                      <Spinner
                        size="small"
                        color="success"
                        style={{
                          position: "absolute",
                          top: 50,
                          left: 50,
                        }}
                      />
                    )}
                  </div>
                  <div className="flex-1 align-self-center mx-2">
                    <div className="text-muted">
                      <h5>{`${props?.currentUser?.data?.fname || ""} ${
                        props?.currentUser?.data?.lname || ""
                      }`}</h5>
                      <p className="mb-1">{email}</p>
                      <p className="mb-0"></p>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <h4 className="card-title mb-4">Change Credentials</h4>
        <Card>
          <CardBody>
            <div className="col-lg-12">
              <div className="row mb-3">
                <div className="col-lg-12">
                  <label htmlFor="email" className="col-form-label">
                    Email
                  </label>
                  <Input
                    type="email"
                    value={email || ""}
                    onChange={(e) => setEmail(e.target.value)}
                    className="form-control"
                    id="email"
                    style={{
                      maxWidth: 300,
                    }}
                  />
                  <input
                    type="file"
                    accept="image/png, image/svg, image/jpeg, image/jpg"
                    className="d-none"
                    id="tag_avatar_file"
                    onChange={onAvatarInputChange}
                    ref={tagAvatarInputRef}
                    multiple={false}
                    disabled={props?.newAvatarUpload?.loading}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="row mb-3">
                <div className="col-lg-12">
                  <AvForm
                    className="form-horizontal"
                    onValidSubmit={(e, v) => handleValidSubmit(e, v)}
                  >
                    <div
                      className="mb-3"
                      style={{ position: "relative", maxWidth: 300 }}
                    >
                      <AvGroup>
                        <Label>New Password</Label>
                        <AvInput
                          name="password"
                          required
                          type={showPassword ? "text" : "password"}
                          placeholder="Enter New Password"
                          validate={{
                            minLength: {
                              value: 6,
                            },
                          }}
                        />
                        <div
                          style={{
                            position: "absolute",
                            left: "82%",
                            marginTop: "-30px",
                          }}
                        >
                          {showPassword ? (
                            <AiOutlineEyeInvisible
                              size={20}
                              className="text-muted"
                              style={{ cursor: "pointer" }}
                              onClick={handleShowPassword}
                            />
                          ) : (
                            <AiOutlineEye
                              size={20}
                              className="text-muted"
                              style={{ cursor: "pointer" }}
                              onClick={handleShowPassword}
                            />
                          )}
                        </div>
                        <AvFeedback>New Password should be greater than 6 characters</AvFeedback>
                      </AvGroup>
                    </div>
                    <Row className="row mt-4">
                      <Col className="col-12 text-start">
                        <button
                          className="btn btn-primary w-md waves-effect waves-light"
                          type="submit"
                          disabled={props.loading}
                          style={{
                            maxWidth: 300,
                          }}
                        >
                          {props.loading ? (
                            <div
                              class="spinner-border spinner-border-sm text-light"
                              role="status"
                            >
                              <span class="sr-only">Loading...</span>
                            </div>
                          ) : (
                            "Save Changes"
                          )}
                        </button>
                      </Col>
                    </Row>
                  </AvForm>
                </div>
              </div>
            </div>
          </CardBody>
        </Card>
      </div>
    </React.Fragment>
  );
};

Settings.propTypes = {
  currentUser: PropTypes.object,
  uploadAvatar: PropTypes.func,
  uploadAvatarReset: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
    newAvatarUpload: state.newAvatarUpload,
    error: state.SetPassword.error,
    loading: state.SetPassword.loading,
  };
};

export default connect(mapStateToProps, {
  uploadAvatar: newAvatarUpload,
  uploadAvatarReset: onNewAvatarUploadReset,
  setPasswordAC: setPasswordAC,
})(Settings);
