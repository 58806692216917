import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { Row, Col, Alert, Container } from "reactstrap";

// Redux
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

// action
import { userForgetPassword } from "../../store/actions";

const ForgetPasswordPage = (props) => {
  useEffect(() => {
    document.body.className = "authentication-bg";
    // remove classname when component will unmount
    return function cleanup() {
      document.body.className = "";
    };
  });

  function handleValidSubmit(_, values) {
    props.userForgetPassword(values, props.history);
  }

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-white">
          <i className="fas fa-home h2 text-white" />
        </Link>
      </div>
      <Container>
        <Row className="account-pages my-5 pt-sm-5">
          <div className="col-lg-6 p-0">
            <img
              src={`${process.env.PUBLIC_URL}/images/tap.gif`}
              alt="logo"
              className="img-full img-fluid"
            />
          </div>
          <Col md={8} lg={6} xl={5} className="p-0 ">
            <div className="card overflow-hidden rounded-0 mt-5">
              <div className="card-body pt-5 forget-card-body">
                <div className="text-center mx-auto logo-section">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/logo.png`}
                    alt="about"
                    className="col-9 img-fluid"
                  />
                </div>
                <div className="p-2">
                  <div className="p-2 ">
                    <AvForm
                      className="form-horizontal"
                      style={{ marginTop: "-30px" }}
                      onValidSubmit={(e, v) => handleValidSubmit(e, v)}
                    >
                      {props.forgetError &&
                      typeof props.forgetError === "string" ? (
                        <Alert
                          color="danger"
                          className="text-center mb-4 "
                          style={{ marginTop: "13px" }}
                        >
                          {props.forgetError}
                        </Alert>
                      ) : null}
                      {props.forgetSuccessMsg ? (
                        <Alert
                          color="success"
                          className="text-center mb-4"
                          style={{ marginTop: "13px" }}
                        >
                          {props.forgetSuccessMsg}
                        </Alert>
                      ) : null}
                      <div className="mb-3">
                        <AvField
                          name="email"
                          label="Email"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          required
                        />
                      </div>
                      <Row className="row mb-0">
                        <Col className="col-12 text-end">
                          <button
                            className="btn btn-primary w-md waves-effect waves-light w-100"
                            type="submit"
                            disabled={props?.loading}
                          >
                            {props?.loading ? (
                              <div
                                class="spinner-border spinner-border-sm text-light"
                                role="status"
                              >
                                <span class="sr-only">Loading...</span>
                              </div>
                            ) : (
                              "Reset"
                            )}
                          </button>
                        </Col>
                      </Row>
                    </AvForm>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { forgetError, forgetSuccessMsg, loading } = state.ForgetPassword;
  return { forgetError, forgetSuccessMsg, loading };
};
export default withRouter(
  connect(mapStatetoProps, { userForgetPassword })(ForgetPasswordPage)
);
ForgetPasswordPage.propTypes = {
  forgetError: PropTypes.any,
  forgetSuccessMsg: PropTypes.any,
  history: PropTypes.object,
  userForgetPassword: PropTypes.func,
};
