import {
  MODIFY_CUSTOMER_TAG,
  MODIFY_CUSTOMER_TAG_SUCCESS,
  MODIFY_CUSTOMER_TAG_FAIL,
} from "./actionTypes";

export const modifyCustomerTag = (tagId, update) => {
  return {
    type: MODIFY_CUSTOMER_TAG,
    payload: {
      tagId,
      update,
      loading: true,
      message: null,
      error: null,
    },
  };
};

export const onModifyCustomerTagSuccess = (message) => {
  return {
    type: MODIFY_CUSTOMER_TAG_SUCCESS,
    payload: {
      message,
      loading: false,
      error: null,
    },
  };
};

export const onModifyCustomerTagFail = (error) => {
  return {
    type: MODIFY_CUSTOMER_TAG_FAIL,
    payload: {
      error,
      message: null,
      loading: false,
    },
  };
};
