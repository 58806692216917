import {
  REMOVE_CUSTOMER_TAG,
  REMOVE_CUSTOMER_TAG_SUCCESS,
  REMOVE_CUSTOMER_TAG_FAIL,
} from "./actionTypes";

export const removeCustomerTag = (tagId) => {
  return {
    type: REMOVE_CUSTOMER_TAG,
    payload: {
      tagId,
      loading: true,
      message: null,
      error: null,
    },
  };
};

export const onRemoveCustomerTagSuccess = (message) => {
  return {
    type: REMOVE_CUSTOMER_TAG_SUCCESS,
    payload: {
      message,
      loading: false,
      error: null,
    },
  };
};

export const onRemoveCustomerTagFail = (error) => {
  return {
    type: REMOVE_CUSTOMER_TAG_FAIL,
    payload: {
      error,
      loading: false,
      message: null,
    },
  };
};
