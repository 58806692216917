import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import {
  Switch,
  BrowserRouter as Router,
  Route,
} from "react-router-dom";
import { connect } from "react-redux";

// Import Routes all
import { userRoutes, authRoutes, adminRoutes } from "./routes/allRoutes";

// Import all middleware
import Authmiddleware from "./routes/middleware/Authmiddleware";

// layouts Format
import VerticalLayout from "./components/VerticalLayout/";
import HorizontalLayout from "./components/HorizontalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";

// Import scss
import "./assets/scss/theme.scss";
import { currentUser } from "./store/users/currentUser/actions";
import { U_ROLE_NORMAL, U_ROLE_SUPER_ADMIN } from "./constants/roles";
import Login from "./pages/Authentication/Login";

const App = (props) => {
  const [userRoles, setUserRoles] = useState();

  useEffect(() => {
    if (props?.currentUser?.data?.role) {
      setUserRoles(props?.currentUser?.data?.role?.map?.(({ id }) => id));
    }
  }, [props?.currentUser?.data?.role]);

  useEffect(() => {
    props.getCurrentUser();
  }, []);

  return (
    <React.Fragment>
      <Router>
        <Switch>
          {authRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={NonAuthLayout}
              component={route.component}
              key={idx}
              isAuthProtected={false}
            />
          ))}
          {userRoles && userRoles.includes(U_ROLE_SUPER_ADMIN)
            ? adminRoutes.map((route, idx) => (
                <Authmiddleware
                  path={route.path}
                  layout={VerticalLayout}
                  component={route.component}
                  key={idx}
                  isAuthProtected
                  exact
                />
              ))
            : userRoles &&
              userRoles.includes(U_ROLE_NORMAL) &&
              userRoutes.map((route, idx) => (
                <Authmiddleware
                  path={route.path}
                  layout={HorizontalLayout}
                  component={route.component}
                  key={idx}
                  isAuthProtected
                  exact
                />
              ))}
          {!props?.currentUser?.loading && !userRoles && (
            <Route path="/" render={(props = {}) => <Login {...props} />} />
          )}
        </Switch>
      </Router>
    </React.Fragment>
  );
};

App.propTypes = {
  layout: PropTypes.any,
  currentUser: PropTypes.object,
  getCurrentUser: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    layout: state.Layout,
    currentUser: state.currentUser,
  };
};

export default connect(mapStateToProps, { getCurrentUser: currentUser })(App);
