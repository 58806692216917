import { del, get, post, put } from "./api_helper";
import * as url from "./url_helper";

/**
 * all tags
 * @param {{ page: number, size: number }} filter
 * @returns
 */
export const getAllTags = (filter) => {
  let tagsUrl = url.GET_ALL_TAGS;
  const query = Object.entries(filter || {})
    .map(([k, v]) => `${k}=${v}`)
    .join("&");
  if (query) {
    tagsUrl += `?${query}`;
  }
  return get(tagsUrl);
};

// customer's tags
export const getCustomerTags = (userId) =>
  get(`${url.GET_CUSTOMER_TAGS}/${userId}`);

// get tag info
export const getCustomerTagInfo = (tagId) =>
  get(`${url.GET_CUSTOMER_TAG_INFO}/${tagId}`);

// create new
export const createCustomerTag = (newTag) =>
  post(url.POST_CUSTOMER_TAG, newTag);

// update
export const editCustomerTag = (tagId, update) =>
  put(`${url.PUT_CUSTOMER_TAG}/${tagId}`, update);

// remove
export const deleteCustomerTag = (tagId) =>
  del(`${url.DEL_CUSTOMER_TAG}/${tagId}`);

// get stats
export const getCustomerTagsStats = (timeline) =>
  get(`${url.GET_CUSTOMER_TAGS_STATS}?timeline=${timeline}`);
