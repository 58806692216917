import { del, get, post, put } from "./api_helper";
import * as url from "./url_helper";

/**
 * Upload new user avatar
 * @param {File} avatarFile
 */
export const uploadAvatar = (avatarFile) => {
  const data = new FormData();
  data.append("profileAvatar", avatarFile);
  return post(url.POST_NEW_USER_AVATAR, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
