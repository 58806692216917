import React from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";
import { LS_FIELD_JWT } from "../../constants/ls";

const Authmiddleware = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) => {
      if (isAuthProtected && !localStorage.getItem(LS_FIELD_JWT)) {
        props?.history?.push?.("/login");
        props?.history?.go?.();
        return <></>;
      }
      return (
        <Layout>
          <Component {...props} />
        </Layout>
      );
    }}
  />
);

Authmiddleware.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any,
};

export default Authmiddleware;
