import React from "react";
import { Row, Col, CardBody, Card, Progress } from "reactstrap";
import { Link } from "react-router-dom";

//Import Components
import LineChart from "./line-chart";
import RevenueChart from "./revenue-chart";
import SalesAnalytics from "./sales-analytics";
import ScatterChart from "./scatter-analytics";
import LatestTransaction from "./latest-transaction";

//Import Image
import widgetImage from "../../assets/images/widget-img.png";
import Overview from "./Overview";
import Reviews from "./Reviews";
import Revenue from "./Revenue";
import Inbox from "./Inbox";

const Dashboard = () => {
  return (
    <React.Fragment>
      <div className="page-content">
        <Row>
          <div className="col-12">
            <div className="page-title-box d-flex align-items-center justify-content-between">
              <h4 className="page-title mb-0 font-size-18">Dashboard</h4>
              <div className="page-title-right">
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active">
                    Welcome to Bizcotap Dashboard
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </Row>
        <Row>
          <Col lg={3}>
            <Card>
              <CardBody>
                <div className="d-flex align-items-start">
                  <div className="avatar-md font-size-20 me-3">
                    <span className="avatar-title bg-soft-primary text-primary dashboard-icon">
                      <i className="dripicons dripicons-shopping-bag"></i>
                    </span>
                  </div>
                  <div className="flex-1">
                    <div className="font-size-16 mt-2">Total Orders</div>
                    <h4 className="font-weight-bold mt-2">1,368</h4>
                  </div>
                </div>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <div className="d-flex align-items-start">
                  <div className="avatar-md font-size-20 me-3">
                    <span className="avatar-title bg-soft-primary text-primary dashboard-icon">
                      <i className="dripicons dripicons-wallet"></i>
                    </span>
                  </div>
                  <div className="flex-1">
                    <div className="font-size-16 mt-2">Total Revenues</div>
                    <h4 className="font-weight-bold mt-2">$50,456</h4>
                  </div>
                </div>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <div className="d-flex align-items-start">
                  <div className="avatar-md font-size-20 me-3">
                    <span className="avatar-title bg-soft-primary text-primary dashboard-icon">
                      <i className="dripicons dripicons-wallet"></i>
                    </span>
                  </div>
                  <div className="flex-1">
                    <div className="font-size-16 mt-2">Individual Clients</div>
                    <h4 className="font-weight-bold mt-2">1,456</h4>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col lg={3}>
            <Card>
              <CardBody>
                <div className="d-flex align-items-start">
                  <div className="avatar-md font-size-20 me-3">
                    <span className="avatar-title bg-soft-primary text-primary dashboard-icon">
                      <i className="dripicons dripicons-tags"></i>
                    </span>
                  </div>
                  <div className="flex-1">
                    <div className="font-size-16 mt-2">Total Units</div>
                    <h4 className="font-weight-bold mt-2">1,368</h4>
                  </div>
                </div>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <div className="d-flex align-items-start">
                  <div className="avatar-md font-size-20 me-3">
                    <span className="avatar-title bg-soft-primary text-primary dashboard-icon">
                      <i className="dripicons dripicons-view-thumb"></i>
                    </span>
                  </div>
                  <div className="flex-1">
                    <div className="font-size-16 mt-2">Inventory Value</div>
                    <h4 className="font-weight-bold mt-2">$2,300</h4>
                  </div>
                </div>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <div className="d-flex align-items-start">
                  <div className="avatar-md font-size-20 me-3">
                    <span className="avatar-title bg-soft-primary text-primary dashboard-icon">
                      <i className="dripicons dripicons-view-thumb"></i>
                    </span>
                  </div>
                  <div className="flex-1">
                    <div className="font-size-16 mt-2">Business Clients</div>
                    <h4 className="font-weight-bold mt-2">230</h4>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col lg={6}>
            <LineChart />
          </Col>
        </Row>
        <Row>
          <LatestTransaction />
        </Row>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
