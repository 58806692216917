import { takeEvery, put, call } from "redux-saga/effects";

// Login Redux States
import { MODIFY_CUSTOMER_TAG } from "./actionTypes";
import { onModifyCustomerTagSuccess, onModifyCustomerTagFail } from "./actions";

import { editCustomerTag } from "../../../helpers/tags";

function* modifyCustomerTag(action) {
  try {
    const response = yield call(
      editCustomerTag,
      action?.payload?.tagId,
      action?.payload?.update
    );
    if (response?.ok == 1) {
      yield put(onModifyCustomerTagSuccess(response?.message));
    } else {
      yield put(onModifyCustomerTagFail(response?.message));
    }
  } catch (error) {
    yield put(onModifyCustomerTagFail(error?.message || error));
  }
}

function* modifyCustomerTagSaga() {
  yield takeEvery(MODIFY_CUSTOMER_TAG, modifyCustomerTag);
}

export default modifyCustomerTagSaga;
