import { call, put, takeLeading } from "redux-saga/effects";

// Login Redux States
import { LOGIN_USER, LOGOUT_USER, SOCIAL_LOGIN } from "./actionTypes";
import {
  apiError,
  loginError,
  loginSuccess,
  logoutError,
  logoutUserSuccess,
} from "./actions";

import { onCurrUserSuccess } from "../../users/currentUser/actions";

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper";
import { postJwtLogin, postSocialLogin } from "../../../helpers/auth";
import { LS_FIELD_JWT } from "../../../constants/ls";

function* loginUser({ payload: { user, history } }) {
  try {
    const response = yield call(postJwtLogin, {
      email: user.email,
      password: user.password,
    });
    console.log("resp", response)
    if (response?.ok == 1) {
      localStorage.setItem(LS_FIELD_JWT, response?.data?.token);
      yield put(loginSuccess(response?.data?.user));
      yield put(onCurrUserSuccess(response?.data?.user));
      history?.push?.("/dashboard");
      history?.go?.();
    } else {
      yield put(loginError(response?.message || "Login failed!"));
    }
  } catch (error) {
    yield put(loginError(error?.message || error));
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem(LS_FIELD_JWT);
    history.push("/login");
    yield put(logoutUserSuccess());
    history?.go?.();
  } catch (error) {
    yield put(logoutError(error?.message || error));
  }
}

function* socialLogin({ payload: { data, history, type } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const fireBaseBackend = getFirebaseBackend();
      const response = yield call(fireBaseBackend.socialLoginUser, data, type);
      localStorage.setItem("authUser", JSON.stringify(response));
      yield put(loginSuccess(response));
    } else {
      const response = yield call(postSocialLogin, data);
      localStorage.setItem("authUser", JSON.stringify(response));
      yield put(loginSuccess(response));
    }
    history.push("/dashboard");
  } catch (error) {
    yield put(apiError(error));
  }
}

function* authSaga() {
  yield takeLeading(LOGIN_USER, loginUser);
  yield takeLeading(SOCIAL_LOGIN, socialLogin);
  yield takeLeading(LOGOUT_USER, logoutUser);
}

export default authSaga;
