import { takeEvery, put, call, takeLatest } from "redux-saga/effects";

// Login Redux States
import { CUSTOMER_TAG_INFO } from "./actionTypes";
import { onCustomerTagInfoSuccess, onCustomerTagInfoFail } from "./actions";

import { getCustomerTagInfo } from "../../../helpers/tags";

function* customerTagInfo(action) {
  try {
    const response = yield call(getCustomerTagInfo, action?.payload?.tagId);
    if (response?.ok == 1) {
      yield put(onCustomerTagInfoSuccess(response?.data));
    } else {
      yield put(onCustomerTagInfoFail(response?.message));
    }
  } catch (error) {
    yield put(onCustomerTagInfoFail(error?.message || error));
  }
}

function* customerTagInfoSaga() {
  yield takeLatest(CUSTOMER_TAG_INFO, customerTagInfo);
}

export default customerTagInfoSaga;
