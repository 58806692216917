import { CUSTOMERS, CUSTOMERS_SUCCESS, CUSTOMERS_FAIL } from "./actionTypes";

export const customers = () => {
  return {
    type: CUSTOMERS,
    payload: {
      loading: true,
    },
  };
};

export const onCustomersSuccess = (data = []) => {
  return {
    type: CUSTOMERS_SUCCESS,
    payload: {
      data,
      loading: false,
    },
  };
};

export const onCustomersFail = (error) => {
  return {
    type: CUSTOMERS_FAIL,
    payload: {
      error,
      loading: false,
    },
  };
};
