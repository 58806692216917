import {
  CUSTOMER_TAGS_STATS,
  CUSTOMER_TAGS_STATS_SUCCESS,
  CUSTOMER_TAGS_STATS_FAIL,
} from "./actionTypes";

const initialState = {
  loading: false,
  message: null,
  error: null,
  data: null,
};

const customerTagsStats = (state = initialState, action) => {
  switch (action.type) {
    case CUSTOMER_TAGS_STATS:
    case CUSTOMER_TAGS_STATS_SUCCESS:
    case CUSTOMER_TAGS_STATS_FAIL:
      state = {
        ...state,
        ...action.payload,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default customerTagsStats;
