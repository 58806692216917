import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

//i18n
import { withTranslation } from "react-i18next";
// Redux
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

const ProfileMenu = (props) => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);
  const [username, setusername] = useState("Profile");

  useEffect(() => {
    if (props?.currentUser?.data?.fname) {
      setusername(props?.currentUser?.data?.fname);
    }
  }, [props?.currentUser?.data?.fname]);

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item waves-effect"
          id="page-header-user-dropdown"
          tag="button"
        >
          {props?.currentUser?.data?.avatar && (
            <img
              src={props?.currentUser?.data?.avatar}
              alt=""
              className="rounded-circle header-profile-user profile"
            />
          )}{" "}
          <span className="d-none d-xl-inline-block ms-1">{username}</span>{" "}
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block"></i>{" "}
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem tag="a" href="/profile">
            {" "}
            <i className="bx bx-user font-size-16 align-middle me-1"></i>{" "}
            {"View Profile"}{" "}
          </DropdownItem>
          <DropdownItem tag="a" href="#">
            <i className="bx bx-wrench font-size-16 align-middle me-1"></i>{" "}
            {"Settings"}
          </DropdownItem>

          <div className="dropdown-divider" />
          <Link to="/logout" className="dropdown-item text-danger">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger"></i>{" "}
            <span>{"Logout"}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

ProfileMenu.propTypes = {
  currentUser: PropTypes.object,
};

const mapStatetoProps = (state) => {
  return {
    currentUser: state.currentUser,
  };
};

export default connect(mapStatetoProps, null)(ProfileMenu);
