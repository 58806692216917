import {
  NEW_CUSTOMER_TAG,
  NEW_CUSTOMER_TAG_SUCCESS,
  NEW_CUSTOMER_TAG_FAIL,
} from "./actionTypes";

export const newCustomerTag = (newTag) => {
  return {
    type: NEW_CUSTOMER_TAG,
    payload: {
      newTag,
      loading: true,
      message: null,
      error: null,
    },
  };
};

export const onNewCustomerTagSuccess = (message) => {
  return {
    type: NEW_CUSTOMER_TAG_SUCCESS,
    payload: {
      message,
      loading: false,
      error: null,
    },
  };
};

export const onNewCustomerTagFail = (error) => {
  return {
    type: NEW_CUSTOMER_TAG_FAIL,
    payload: {
      error,
      loading: false,
      message: null,
    },
  };
};
