import { takeEvery, put, call } from "redux-saga/effects";

// Login Redux States
import { CUSTOMER_TAGS } from "./actionTypes";
import { onCustomerTagsSuccess, onCustomerTagsFail } from "./actions";

import { getCustomerTags } from "../../../helpers/tags";

function* customerTags(action) {
  try {
    const response = yield call(getCustomerTags, action?.payload?.userId);
    if (response?.ok == 1) {
      yield put(onCustomerTagsSuccess(response?.data));
    } else {
      yield put(onCustomerTagsFail(response?.message));
    }
  } catch (error) {
    yield put(onCustomerTagsFail(error?.message || error));
  }
}

function* customerTagsSaga() {
  yield takeEvery(CUSTOMER_TAGS, customerTags);
}

export default customerTagsSaga;
