import { combineReducers } from "redux";

// Front
import Layout from "./layout/reducer";

// Authentication
import Login from "./auth/login/reducer";
import Account from "./auth/register/reducer";
import ForgetPassword from "./auth/forgetpwd/reducer";
import SetPassword from "./auth/setpassword/reducer";
import Profile from "./auth/profile/reducer";
import currentUser from "./users/currentUser/reducer";
import customers from "./users/customers/reducer";
import allTags from "./tags/allTags/reducer";
import customerTags from "./tags/customerTags/reducer";
import customerTagInfo from "./tags/customerTagInfo/reducer";
import newCustomerTag from "./tags/newCustomerTag/reducer";
import modifyCustomerTag from "./tags/modifyCustomerTag/reducer";
import removeCustomerTag from "./tags/removeCustomerTag/reducer";
import newAvatarUpload from "./uploads/newAvatarUpload/reducer";
import customerTagsStats from "./tags/customerTagsStats/reducer";

//Calendar
import calendar from "./calendar/reducer";

//chat
import chat from "./chat/reducer";

//contacts
import contacts from "./contacts/reducer";

//tasks
import tasks from "./tasks/reducer";

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  ForgetPassword,
  SetPassword,
  Profile,
  calendar,
  chat,
  tasks,
  contacts,
  currentUser,
  customers,
  allTags,
  customerTags,
  customerTagInfo,
  newCustomerTag,
  modifyCustomerTag,
  removeCustomerTag,
  newAvatarUpload,
  customerTagsStats,
});

export default rootReducer;
