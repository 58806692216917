import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import { Row, Col, UncontrolledAlert as Alert, Container } from "reactstrap";

// Redux
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

// availity-reactstrap-validation
import {
  AvForm,
  AvField,
  AvInput,
  AvFeedback,
  AvGroup,
} from "availity-reactstrap-validation";
import { Label } from "reactstrap";

// actions
import {
  loginUser,
  apiError,
  socialLogin,
  loginError,
} from "../../store/actions";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

const Login = (props) => {
  // show and hide password state
  const [showPassword, setShowPassword] = useState(false);

  // handleValidSubmit
  const handleValidSubmit = (event, values) => {
    props.loginUser(values, props.history);
  };

  // Handle Show and Hide Password
  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    document.body.className = "authentication-bg";
    // remove classname when component will unmount
    return function cleanup() {
      document.body.className = "";
    };
  }, []);

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-white">
          <i className="fas fa-home h2 text-white" />
        </Link>
      </div>
      <Container>
        <Row className="account-pages my-5 pt-sm-5">
          <div className="col-lg-6 p-0 d-none d-sm-none d-md-block">
            <img
              src={`${process.env.PUBLIC_URL}/images/tap.gif`}
              alt="logo"
              className="img-full img-fluid"
            />
          </div>
          <Col md={8} lg={6} xl={5} className="p-0 pt-sm-0">
            <div className="card overflow-hidden rounded-0">
              <div className="card-body login-card-body">
                <div className="text-center mx-auto logo-section">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/logo.png`}
                    alt="about"
                    className="col-9 img-fluid"
                  />
                </div>
                <div className="p-2">
                  <AvForm
                    className="form-horizontal"
                    onValidSubmit={(e, v) => {
                      handleValidSubmit(e, v);
                    }}
                  >
                    {props.error && typeof props.error === "string" ? (
                      <Alert color="danger">{props.error}</Alert>
                    ) : null}
                    <div className="mb-3">
                      <AvField
                        name="email"
                        label="Email"
                        className="form-control"
                        placeholder="Enter email"
                        type="email"
                        required
                      />
                    </div>
                    <div className="mb-3" style={{ position: "relative" }}>
                      <AvGroup>
                        <Label>Password</Label>
                        <AvInput
                          name="password"
                          required
                          type={showPassword ? "text" : "password"}
                          placeholder="Enter Password"
                        />
                        <div
                          style={{
                            position: "absolute",
                            right: 30,
                            marginTop: "-30px",
                          }}
                        >
                          {showPassword ? (
                            <AiOutlineEyeInvisible
                              size={20}
                              className="text-muted"
                              style={{ cursor: "pointer" }}
                              onClick={handleShowPassword}
                            />
                          ) : (
                            <AiOutlineEye
                              size={20}
                              className="text-muted"
                              style={{ cursor: "pointer" }}
                              onClick={handleShowPassword}
                            />
                          )}
                        </div>
                        <AvFeedback>Password is Required</AvFeedback>
                      </AvGroup>
                    </div>
                    <div className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="customControlInline"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="customControlInline"
                      >
                        Remember me
                      </label>
                    </div>
                    <div className="mt-3">
                      <button
                        className="btn btn-primary w-100 waves-effect waves-light"
                        type="submit"
                        disabled={props?.loading}
                      >
                        {props?.loading ? (
                          <div
                            class="spinner-border spinner-border-sm text-light"
                            role="status"
                          >
                            <span class="sr-only">Loading...</span>
                          </div>
                        ) : (
                          "Log In"
                        )}
                      </button>
                    </div>
                    <div className="mt-4 text-center">
                      <Link to="/forgot-password" className="text-muted">
                        <i className="mdi mdi-lock me-1"></i> Forgot your
                        password?
                      </Link>
                    </div>
                  </AvForm>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { error, loading } = state.Login;
  return { error, loading };
};

export default withRouter(
  connect(mapStateToProps, { loginUser, apiError, socialLogin, loginError })(
    Login
  )
);

Login.propTypes = {
  error: PropTypes.any,
  loading: PropTypes.bool,
  history: PropTypes.object,
  loginUser: PropTypes.func,
  socialLogin: PropTypes.func,
};
