import PropTypes from "prop-types";
import React, { useEffect, useRef, useCallback } from "react";

// //Import Scrollbar
import SimpleBar from "simplebar-react";

// MetisMenu
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";

//i18n
import { withTranslation } from "react-i18next";

const SidebarContent = (props) => {
  const ref = useRef();
  const activateParentDropdown = useCallback((item) => {
    item.classList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }
    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;
      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag
        const parent3 = parent2.parentElement; // li tag
        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }, []);
  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location.pathname;
    const initMenu = () => {
      new MetisMenu("#side-menu");
      let matchingMenuItem = null;
      const ul = document.getElementById("side-menu");
      const items = ul.getElementsByTagName("a");
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i];
          break;
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem);
      }
    };
    initMenu();
  }, [props.location.pathname, activateParentDropdown]);
  useEffect(() => {
    ref.current.recalculate();
  }, []);
  const scrollElement = (item) => {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  };

  return (
    <React.Fragment>
      <SimpleBar ref={ref} className="vertical-simplebar">
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">{"Analytics"} </li>
            <li>
              <Link to="/dashboard" className="waves-effect">
                <i className="dripicons dripicons-graph-pie"></i>
                <span>{"Dashboard"}</span>
              </Link>
            </li>

            <li>
              <Link to="/#" className=" waves-effect">
                <i className="dripicons dripicons-shopping-bag"></i>
                <span>{"orders"}</span>
              </Link>
            </li>

            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="dripicons dripicons-view-thumb"></i>
                <span>{"Products"}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/#">{"Add New"}</Link>
                </li>
                <li>
                  <Link to="/#">{"All Products"}</Link>
                </li>
                <li>
                  <Link to="/#">{"Category"}</Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="mdi mdi-account-group-outline"></i>
                <span>{"Staffs"}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/#">{"Delivery Guy"}</Link>
                </li>
                <li>
                  <Link to="/#">{"Suppliers"}</Link>
                </li>
                <li>
                  <Link to="/#">{"Users"}</Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/customers" className=" waves-effect">
                <i className="dripicons dripicons-user-group"></i>
                <span>{"Customers"}</span>
              </Link>
            </li>

            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="dripicons dripicons-card"></i>
                <span>{"User Tags"}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/tags">{"All Tags"}</Link>
                </li>
                <li>
                  <Link to="/new-tag">{"Add New"}</Link>
                </li>
              </ul>
            </li>
            <li className="menu-title">{"Store Front Settings"} </li>
            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="dripicons dripicons-home"></i>
                <span>{"Home Page"}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/#">{"Clients"}</Link>
                </li>
                <li>
                  <Link to="/#">{"Testimonials"}</Link>
                </li>
                <li>
                  <Link to="/#">{"Trees Formular"}</Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="dripicons dripicons-document"></i>
                <span>{"About us Page"}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/#">{"About us"}</Link>
                </li>
                <li>
                  <Link to="/#">{"Goals"}</Link>
                </li>
                <li>
                  <Link to="/#">{"Team Members"}</Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/#" className="waves-effect">
                <i className="dripicons dripicons-document-edit"></i>
                <span>{"Compantible List"}</span>
              </Link>
            </li>
            <li>
              <Link to="/#" className="waves-effect">
                <i className="dripicons dripicons-duplicate"></i>
                <span>{"Other Pages"}</span>
              </Link>
            </li>
            <li className="menu-title">{"Settings"} </li>
            <li>
              <Link to="/#" className=" waves-effect">
                <i className="dripicons dripicons-wallet"></i>
                <span>{"Payment Settings"}</span>
              </Link>
            </li>
            <li>
              <Link to="/#" className=" waves-effect">
                <i className="dripicons dripicons-gear"></i>
                <span>{"User Account"}</span>
              </Link>
            </li>
            {/* <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="mdi mdi-account-circle-outline"></i>
                <span>{("Pages")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/pages-login">{("Login")}</Link>
                </li>
                <li>
                  <Link to="/pages-register">{("Register")}</Link>
                </li>
                <li>
                  <Link to="/page-recoverpw">
                    {("Recover Password")}
                  </Link>
                </li>
                <li>
                  <Link to="/auth-lock-screen">{("Lock Screen")}</Link>
                </li>
                <li>
                  <Link to="/pages-starter">{("Starter Page")}</Link>
                </li>
                <li>
                  <Link to="/invoice">{("Invoice")}</Link>
                </li>
                <li>
                  <Link to="/profile">{("Profile")}</Link>
                </li>
                <li>
                  <Link to="/pages-maintenance">{("Maintenance")}</Link>
                </li>
                <li>
                  <Link to="/pages-comingsoon">{("Coming Soon")}</Link>
                </li>
                <li>
                  <Link to="/pages-timeline">{("Timeline")}</Link>
                </li>
                <li>
                  <Link to="/pages-faqs">{("FAQs")}</Link>
                </li>
                <li>
                  <Link to="/pages-pricing">{("Pricing")}</Link>
                </li>
                <li>
                  <Link to="/pages-404">{("Error 404")}</Link>
                </li>
                <li>
                  <Link to="/pages-500">{("Error 500")}</Link>
                </li>
              </ul>
            </li>

            <li className="menu-title">{("Components")}</li>

            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="mdi mdi-checkbox-multiple-blank-outline"></i>
                <span>{("UI Elements")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/ui-alerts">{("Alerts")}</Link>
                </li>
                <li>
                  <Link to="/ui-buttons">{("Buttons")}</Link>
                </li>
                <li>
                  <Link to="/ui-cards">{("Cards")}</Link>
                </li>
                <li>
                  <Link to="/ui-carousel">{("Carousel")}</Link>
                </li>
                <li>
                  <Link to="/ui-dropdowns">{("Dropdowns")}</Link>
                </li>
                <li>
                  <Link to="/ui-grid">{("Grid")}</Link>
                </li>
                <li>
                  <Link to="/ui-images">{("Images")}</Link>
                </li>
                <li>
                  <Link to="/ui-lightbox">{("Lightbox")}</Link>
                </li>
                <li>
                  <Link to="/ui-modals">{("Modals")}</Link>
                </li>
                <li>
                  <Link to="/ui-rangeslider">{("Range Slider")}</Link>
                </li>
                <li>
                  <Link to="/ui-session-timeout">
                    {("Session Timeout")}
                  </Link>
                </li>
                <li>
                  <Link to="/ui-progressbars">{("Progress Bars")}</Link>
                </li>
                <li>
                  <Link to="/ui-sweet-alert">{("Sweet-Alert")}</Link>
                </li>
                <li>
                  <Link to="/ui-tabs-accordions">
                    {("Tabs & Accordions")}
                  </Link>
                </li>
                <li>
                  <Link to="/ui-typography">{("Typography")}</Link>
                </li>
                <li>
                  <Link to="/ui-video">{("Video")}</Link>
                </li>
                <li>
                  <Link to="/ui-general">{("General")}</Link>
                </li>
                <li>
                  <Link to="/ui-colors">{("Colors")}</Link>
                </li>
                <li>
                  <Link to="/ui-rating">{("Rating")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="waves-effect">
                <i className="mdi mdi-newspaper"></i>
                <span className="badge rounded-pill bg-danger float-end">6</span>
                <span>{("Forms")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/basic-elements">{("Basic Elements")}</Link>
                </li>
                <li>
                  <Link to="/form-validation">
                    {("Form Validation")}
                  </Link>
                </li>
                <li>
                  <Link to="/form-advanced">{("Form Advanced")}</Link>
                </li>
                <li>
                  <Link to="/form-editors">{("Form Editors")}</Link>
                </li>
                <li>
                  <Link to="/form-uploads">{("Form File Upload")} </Link>
                </li>
                <li>
                  <Link to="/form-xeditable">{("Form Xeditable")}</Link>
                </li>
                <li>
                  <Link to="/form-repeater">{("Form Repeater")}</Link>
                </li>
                <li>
                  <Link to="/form-wizard">{("Form Wizard")}</Link>
                </li>
                <li>
                  <Link to="/form-mask">{("Form Mask")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="mdi mdi-clipboard-list-outline"></i>
                <span>{("Tables")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/tables-basic">{("Basic Tables")}</Link>
                </li>
                <li>
                  <Link to="/tables-datatable">{("Data Tables")}</Link>
                </li>
                <li>
                  <Link to="/tables-responsive">
                    {("Responsive Table")}
                  </Link>
                </li>
                <li>
                  <Link to="/tables-editable">{("Editable Table")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="mdi mdi-chart-donut"></i>
                <span>{("Charts")}</span>
              </Link>

              <ul className="sub-menu">
                <li>
                  <Link to="/apex-charts">{("Apex charts")}</Link>
                </li>
                <li>
                  <Link to="/chartjs-charts">{("Chartist Chart")}</Link>
                </li>
                <li>
                  <Link to="/e-charts">{("E Chart")}</Link>
                </li>
                <li>
                  <Link to="/charts-knob">{("Jquery Knob")}</Link>
                </li>
                <li>
                  <Link to="/sparkline-charts">
                    {("Sparkline Chart")}
                  </Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="mdi mdi-emoticon-happy-outline"></i>
                <span>{("Icons")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/icons-boxicons">{("Boxicons")}</Link>
                </li>
                <li>
                  <Link to="/icons-materialdesign">
                    {("Material Design")}
                  </Link>
                </li>
                <li>
                  <Link to="/icons-dripicons">{("Dripicons")}</Link>
                </li>
                <li>
                  <Link to="/icons-fontawesome">{("Font awesome")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="mdi mdi-map-marker-outline"></i>
                <span>{("Maps")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/maps-google">{("Google Maps")}</Link>
                </li>
                <li>
                  <Link to="/maps-vector">{("Vector Maps")}</Link>
                </li>
                <li>
                  <Link to="/maps-leaflet">{("Leaflet Maps")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="mdi mdi-file-tree"></i>
                <span>{("Multi Level")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/#">{("Level 1.1")}</Link>
                </li>
                <li>
                  <Link to="/#" className="has-arrow">
                    {("Level 1.2")}
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/#">{("Level 2.1")}</Link>
                    </li>
                    <li>
                      <Link to="/#">{("Level 2.2")}</Link>
                    </li>
                  </ul>
                </li>
              </ul>
            </li> */}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(SidebarContent));
