import React, { useState } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import {
  UncontrolledAlert as Alert,
  Button,
  Spinner,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  CardTitle,
  Input,
} from "reactstrap";
import {
  GET_QRCODE_OFFLINE,
  GET_QRCODE_ONLINE,
} from "../../helpers/url_helper";
import { U_ROLE_SUPER_ADMIN } from "../../constants/roles";

const APP_PROFILE_URL = process.env.REACT_APP_PROFILE_URL;
const APP_API_URL = process.env.REACT_APP_API_URL;

const TagRow = ({
  tag,
  pos = 1,
  onAttemptDeleteTag,
  deleteTagState,
  activeDownloadMenu,
  setActiveDownloadMenu,
  currentUser,
}) => {
  return (
    <tr>
      <th>{pos}</th>
      <td style={{ minWidth: 100 }}>{tag?.tag_info?.fname || ""}</td>
      <td style={{ minWidth: 100 }}>{tag?.tag_info?.lname || ""}</td>
      <td style={{ minWidth: 500 }} className="text-right align-content-sm-end" >
        {tag.tuid && (
          <a
            className="btn btn-sm btn-primary actionBtn mx-2"
            href={`${APP_PROFILE_URL}/profile/${tag.tuid}`}
            target="__blank"
          >
            View
          </a>
        )}
        <Link
          className="btn btn-sm btn-primary actionBtn mx-2"
          to={`/tag-info/${tag.id}`}
        >
          Edit
        </Link>
        <ButtonDropdown
          isOpen={activeDownloadMenu == tag?.id}
          toggle={() => {
            setActiveDownloadMenu(activeDownloadMenu ? undefined : tag?.id);
          }}
        >
          <DropdownToggle size="sm" color="success" className="mx-2">
            Download QR Code <i className="mdi mdi-chevron-down"></i>
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem
              href={`${APP_API_URL}${GET_QRCODE_OFFLINE}/${tag?.tuid}`}
            >
              Offline
            </DropdownItem>
            <DropdownItem
              href={`${APP_API_URL}${GET_QRCODE_ONLINE}/${tag?.tuid}`}
            >
              Online
            </DropdownItem>
          </DropdownMenu>
        </ButtonDropdown>
        {currentUser?.data?.role?.find(({ id }) => id == U_ROLE_SUPER_ADMIN) !=
          null && (
          <Button
            type="button"
            className="btn btn-sm btn-danger actionBtn mx-2"
            onClick={() => onAttemptDeleteTag(tag)}
            disabled={
              deleteTagState?.loading == true && deleteTagState?.tagId == tag.id
            }
          >
            Remove
          </Button>
        )}
      </td>
    </tr>
  );
};

const Tags = (props) => {
  const [activeDownloadMenu, setActiveDownloadMenu] = useState(10);
  const [isPageSizeMenuOpen, setIsPageSizeMenuOpen] = useState(false);
  const [tagToDelete, setTagToDelete] = useState();

  const onConfirmDeleteTag = () => {
    if (tagToDelete?.id) {
      const tagId = tagToDelete?.id;
      props?.onDeleteTag(tagId);
      setTagToDelete(undefined);
    }
  };

  const onCancelDeleteTag = () => {
    setTagToDelete(undefined);
  };

  const onAttemptDeleteTag = (tag) => {
    if (tag && tag?.id) {
      setTagToDelete(tag);
    }
  };

  return (
    <React.Fragment>
      <div className="">
        {props?.deleteTagState?.error &&
        typeof props?.deleteTagState.error === "string" ? (
          <Alert color="warning">{props?.deleteTagState?.error}</Alert>
        ) : null}
        {props?.deleteTagState?.message &&
        typeof props?.deleteTagState.message === "string" ? (
          <Alert color="success">{props?.deleteTagState?.message}</Alert>
        ) : null}
        <div className="row d-flex align-items-center justify-content-center mb-3">
          <div className="col-lg-6 col-md-12 d-flex align-items-center justify-content-start">
            <CardTitle>
              {props?.title || "All Tags"}{" "}
              {props?.tagsState?.data?.total
                ? `(${props?.tagsState?.data?.total})`
                : ""}
              {props?.tagsState?.loading && (
                <Spinner size="sm" color="primary" className="mx-3" />
              )}
            </CardTitle>
          </div>
          <div className="col-lg-6 col-md-12 d-flex align-items-center justify-content-end">
            <Input
              type="search"
              bsSize="sm"
              value={props?.search}
              onChange={(e) => props?.onSearch(e.target.value)}
              className="mr-2"
              placeholder="Search by name, company, etc..."
              style={{ width: 340 }}
            />
            <Link
              to="/new-tag"
              className="btn btn-sm btn-primary mx-2"
              style={{ minWidth: 30 }}
            >
              <i className="mdi mdi-plus" aria-hidden="true"></i>
            </Link>
            <Button
              size="sm"
              color="primary"
              className=""
              onClick={props?.onRefresh}
              style={{ minWidth: 30 }}
            >
              <i className="mdi mdi-refresh spin" aria-hidden="true"></i>
            </Button>
          </div>
        </div>
        <div className="table-responsive">
          <Table
            id="allTags"
            className="table-centered table-nowrap"
            size="sm"
            responsive
          >
            <thead>
              <tr>
                <th>#</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {props?.tagsState?.data?.data?.map?.((tag, idx) => (
                <TagRow
                  key={tag?.id}
                  tag={tag}
                  pos={idx + 1}
                  onAttemptDeleteTag={onAttemptDeleteTag}
                  deleteTagState={props?.deleteTagState}
                  activeDownloadMenu={activeDownloadMenu}
                  setActiveDownloadMenu={setActiveDownloadMenu}
                  currentUser={props?.currentUser}
                />
              ))}
            </tbody>
          </Table>
        </div>
        {props?.tagsState?.data?.to <= props?.tagsState?.data?.total && (
          <div className="d-flex align-items-center justify-content-between">
            <ButtonDropdown
              isOpen={isPageSizeMenuOpen}
              toggle={() => setIsPageSizeMenuOpen(!isPageSizeMenuOpen)}
            >
              <DropdownToggle
                size="sm"
                color="transparent"
                className="mr-2 fw-bold"
              >
                Display {props?.pageSize || ""} tags per page{" "}
                <i className="mdi mdi-chevron-down" aria-hidden></i>
              </DropdownToggle>
              <DropdownMenu>
                {[7, 10, 20, 50, 100, 200].map((pSize) => (
                  <DropdownItem
                    key={pSize}
                    onClick={() => props?.onChangePageSize(pSize)}
                  >
                    {pSize}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </ButtonDropdown>
            <Pagination
              size="sm"
              className="pagination pagination-rounded justify-content-end align-items-center mb-0"
            >
              <PaginationItem>
                <PaginationLink onClick={props?.onChangePagePrev}>
                  Prev
                </PaginationLink>
              </PaginationItem>
              <PaginationItem>
                <div className="h-100 d-flex justify-content-center align-items-center fw-bold">
                  <span className="px-2">
                    {props?.activePage || 1}/
                    {props?.tagsState?.data?.last_page || 1} pages{" "}
                  </span>
                </div>
              </PaginationItem>
              {/* {Array(pages)
              .fill(0)
              .map((p, idx) => idx + 1)
              .map((p) => (
                <PaginationItem active={activePage == p}>
                  <PaginationLink onClick={() => onChangePage(p)}>
                    {p}
                  </PaginationLink>
                </PaginationItem>
              ))} */}
              <PaginationItem>
                <PaginationLink onClick={props?.onChangePageNext}>
                  Next
                </PaginationLink>
              </PaginationItem>
            </Pagination>
          </div>
        )}
      </div>
      <Modal isOpen={!!tagToDelete} toggle={onCancelDeleteTag}>
        <ModalHeader toggle={onCancelDeleteTag}>Please Confirm</ModalHeader>
        <ModalBody>
          Do you want to permanently remove this tag (
          <span className="fw-bold">
            {tagToDelete?.tag_info?.fname} {tagToDelete?.tag_info?.lname}
          </span>
          )?
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={onConfirmDeleteTag}>
            Yes, Delete Tag
          </Button>{" "}
          <Button color="secondary" onClick={onCancelDeleteTag}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

Tags.propTypes = {
  activePage: PropTypes.number,
  layout: PropTypes.any,
  currentUser: PropTypes.object,
  tagsState: PropTypes.object,
  deleteTagState: PropTypes.object,
  onDeleteTag: PropTypes.func,
  pageSize: PropTypes.number,
  search: PropTypes.string,
  title: PropTypes.string,
  setPageSize: PropTypes.func,
  onChangePageSize: PropTypes.func,
  onChangePagePrev: PropTypes.func,
  onChangePageNext: PropTypes.func,
  onRefresh: PropTypes.func,
  onSearch: PropTypes.func,
};

export default Tags;
