import {
  CURRENT_USER,
  CURRENT_USER_SUCCESS,
  CURRENT_USER_FAIL,
} from "./actionTypes";

const initialState = {
  error: null,
  data: null,
  loading: false,
};

const currentUser = (state = initialState, action) => {
  switch (action.type) {
    case CURRENT_USER:
    case CURRENT_USER_SUCCESS:
    case CURRENT_USER_FAIL:
      state = {
        ...state,
        ...action.payload,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default currentUser;
