import { takeEvery, put, call } from "redux-saga/effects";

// Login Redux States
import { NEW_AVATAR_UPLOAD, NEW_AVATAR_UPLOAD_RESET } from "./actionTypes";
import {
  onNewAvatarUploadSuccess,
  onNewAvatarUploadFail,
  onNewAvatarUploadReset,
} from "./actions";

import { uploadAvatar } from "../../../helpers/uploads";

function* newAvatarUpload(action) {
  try {
    const response = yield call(() =>
      uploadAvatar(action?.payload?.avatarFile)
    );
    if (response?.ok == 1) {
      yield put(onNewAvatarUploadSuccess(response?.data));
    } else {
      yield put(onNewAvatarUploadFail(response?.message));
    }
  } catch (error) {
    yield put(onNewAvatarUploadFail(error?.message || error));
  }
}

function* newAvatarUploadReset() {
  yield call(onNewAvatarUploadReset);
}

function* newAvatarUploadSaga() {
  yield takeEvery(NEW_AVATAR_UPLOAD, newAvatarUpload);
  yield takeEvery(NEW_AVATAR_UPLOAD_RESET, newAvatarUploadReset);
}

export default newAvatarUploadSaga;
