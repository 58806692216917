import { ALL_TAGS, ALL_TAGS_SUCCESS, ALL_TAGS_FAIL } from "./actionTypes";

/**
 *
 * @param {{ page: number, pageSize: number, search: string }} filter
 * @returns
 */
export const allTags = (
  filter = { mine: 0, page: 1, size: 10, search: "" }
) => {
  return {
    type: ALL_TAGS,
    payload: {
      filter,
      loading: true,
    },
  };
};

export const onAllTagsSuccess = (data) => {
  return {
    type: ALL_TAGS_SUCCESS,
    payload: {
      data,
      loading: false,
    },
  };
};

export const onAllTagsFail = (error) => {
  return {
    type: ALL_TAGS_FAIL,
    payload: {
      error,
      loading: false,
    },
  };
};
