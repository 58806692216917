import {
  MODIFY_CUSTOMER_TAG,
  MODIFY_CUSTOMER_TAG_SUCCESS,
  MODIFY_CUSTOMER_TAG_FAIL,
} from "./actionTypes";

const initialState = {
  loading: false,
  message: null,
  error: null,
  data: null,
  tagId: null,
  update: null,
};

const modifyCustomerTag = (state = initialState, action) => {
  switch (action.type) {
    case MODIFY_CUSTOMER_TAG:
    case MODIFY_CUSTOMER_TAG_SUCCESS:
    case MODIFY_CUSTOMER_TAG_FAIL:
      state = {
        ...state,
        ...action.payload,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default modifyCustomerTag;
