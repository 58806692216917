import {
  SET_PASSWORD,
  SET_PASSWORD_SUCCESS,
  SET_PASSWORD_ERROR,
} from "./actionTypes"

export const setPasswordAC = (user, history) => {
  return {
    type: SET_PASSWORD,
    payload: { user, history },
  }
}

export const setPasswordSuccessAC = user => {
  return {
    type: SET_PASSWORD_SUCCESS,
    payload: user,
  }
}

export const setPasswordErrorAC = errors => {
  return {
    type: SET_PASSWORD_ERROR,
    payload: errors,
  }
}
