import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import PropTypes from "prop-types";
import {
  Row,
  Col,
  CardBody,
  Card,
  CardTitle,
  Button,
  ButtonGroup,
} from "reactstrap";
import { customerTagsStats } from "../../store/actions";
import { connect } from "react-redux";
import { TIMELINES, TIMELINE_WEEKLY } from "../../constants/timelines";

const OPTIONS = {
  chart: {
    toolbar: {
      show: false,
    },
  },
  plotOptions: {
    bar: {
      horizontal: !1,
      columnWidth: "45%",
      endingShape: "rounded",
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    show: true,
    width: 2,
    colors: ["transparent"],
  },
  colors: ["#45cb85", "#122e45"],
  yaxis: {
    title: {
      text: " (visited/saved)",
    },
  },
  grid: {
    borderColor: "#f1f1f1",
  },
  fill: {
    opacity: 1,
  },
};

const UserDashboard = (props) => {
  const [series, setSeries] = useState([]);
  const [timeline, setTimeline] = useState(TIMELINE_WEEKLY);

  const onData = (data) => {
    if (data && data?.views) {
      const stats = [
        {
          name: "Visited My Profile",
          data: data?.views?.[`${timeline}`],
        },
        {
          name: "Saved My Contact",
          data: data?.downloads?.[`${timeline}`],
        },
      ];
      setSeries(stats);
    }
  };

  useEffect(() => {
    props.getTagsStats(timeline);
  }, [timeline]);

  useEffect(() => {
    onData(props?.tagsStats?.data);
  }, [props?.tagsStats]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Row>
          <div className="col-12">
            <div className="page-title-box d-flex align-items-center justify-content-between">
              <h4 className="page-title mb-0 font-size-18">Dashboard</h4>
              <div className="page-title-right">
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active">
                    Welcome to Bizcotap Dashboard
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </Row>
        <Row>
          <Col lg={4}>
            <Card>
              <CardBody>
                <div className="d-flex align-items-start">
                  <div className="avatar-md font-size-20 me-3">
                    <span className="avatar-title bg-soft-primary text-primary dashboard-icon">
                      <i className="dripicons dripicons-shopping-bag"></i>
                    </span>
                  </div>
                  <div className="flex-1">
                    <div className="font-size-16 mt-2">Total Tags</div>
                    <h4 className="font-weight-bold mt-2">
                      {props?.tagsStats?.data?.tags?.total || 0}
                    </h4>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col lg={4}>
            <Card>
              <CardBody>
                <div className="d-flex align-items-start">
                  <div className="avatar-md font-size-20 me-3">
                    <span className="avatar-title bg-soft-primary text-primary dashboard-icon">
                      <i className="dripicons dripicons-wallet"></i>
                    </span>
                  </div>
                  <div className="flex-1">
                    <div className="font-size-16 mt-2">Profile Visits</div>
                    <h4 className="font-weight-bold mt-2">
                      {props?.tagsStats?.data?.views?.total || 0}
                    </h4>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col lg={4}>
            <Card>
              <CardBody>
                <div className="d-flex align-items-start">
                  <div className="avatar-md font-size-20 me-3">
                    <span className="avatar-title bg-soft-primary text-primary dashboard-icon">
                      <i className="dripicons dripicons-wallet"></i>
                    </span>
                  </div>
                  <div className="flex-1">
                    <div className="font-size-16 mt-2">Saved My Contact</div>
                    <h4 className="font-weight-bold mt-2">
                      {props?.tagsStats?.data?.downloads?.total || 0}
                    </h4>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <Card>
              <CardBody>
                <CardTitle className="mb-4 d-flex flex-column flex-md-row align-items-center justify-content-between">
                  <div className="pb-2">
                    <span>Networking Growth</span>
                  </div>
                  <ButtonGroup size="sm">
                    {TIMELINES.map((t) => (
                      <Button
                        active={timeline == t.value}
                        outline
                        color="success"
                        onClick={() => setTimeline(t.value)}
                      >
                        {t.label}
                      </Button>
                    ))}
                  </ButtonGroup>
                </CardTitle>
                <ReactApexChart
                  options={OPTIONS}
                  series={series}
                  type="bar"
                  height={350}
                  className="apex-charts"
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

UserDashboard.propTypes = {
  currentUser: PropTypes.object,
  tagsStats: PropTypes.object,
  getTagsStats: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
    tagsStats: state.customerTagsStats,
  };
};

export default connect(mapStateToProps, {
  getTagsStats: customerTagsStats,
})(UserDashboard);
