import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Row, Col, Alert, Container, Label } from "reactstrap";

// Redux
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

// availity-reactstrap-validation
import {
  AvForm,
  AvField,
  AvFeedback,
  AvInput,
  AvGroup,
} from "availity-reactstrap-validation";

// react icons
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

// action
import { setPasswordAC } from "../../store/actions";
import { userForgetPassword } from "../../store/actions";

// helper
import { decodeJwtToken } from "../../helpers/decodeJwtToken";
import { LS_FIELD_JWT } from "../../constants/ls";

const SetPasswordPage = (props) => {
  // show and hide password state
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [pwdMatch, setPwdMatch] = useState("");

  // get token from params
  const token = props.match.params.token;
  localStorage.setItem(LS_FIELD_JWT, token);

  // decode token - get duration
  const decodedToken = decodeJwtToken(token);

  // get email from token
  const email = decodedToken?.data?.email;

  // token expired boolean
  const tokenExpired = decodedToken?.exp * 1000 < Date.now();

  // handle submit valid data function
  function handleValidSubmit(_, values) {
    if (values.password === values.confirmPassword) {
      props.setPasswordAC(values, props.history);
    } else {
      setPwdMatch("Password don't match");
    }
  }

  // handle submit valid data function
  function handleResendLink(_, values) {
    props.userForgetPassword(values, props.history);
  }

  // Handle Show and Hide Password
  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  // Handle Show and Hide confirm Password
  const handleShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  // handle on change
  const handleOnChange = () => {
    setPwdMatch("");
  };

  // use effect
  useEffect(() => {
    document.body.className = "authentication-bg";
    // remove classname when component will unmount
    return function cleanup() {
      document.body.className = "";
    };
  }, []);

  return (
    <>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-white">
          <i className="fas fa-home h2 text-white" />
        </Link>
      </div>
      <Container>
        <Row className="account-pages my-5 pt-sm-5">
          <div className="col-lg-6 p-0">
            <img
              src={`${process.env.PUBLIC_URL}/images/tap.gif`}
              alt="logo"
              className="img-full img-fluid"
            />
          </div>
          <Col md={8} lg={6} xl={5} className="p-0 ">
            <div className="card overflow-hidden rounded-0">
              <div className="card-body pt-2 forget-card-body">
                <div className="text-center mx-auto logo-section">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/logo.png`}
                    alt="about"
                    className="col-9 img-fluid"
                  />
                </div>
                {!tokenExpired ? (
                  <div className="p-0">
                    <div className="p-0">
                      {props.error && typeof props.error === "string" ? (
                        <Alert
                          color="danger"
                          className="text-center"
                          style={{ marginTop: "5px" }}
                        >
                          {props.error}
                        </Alert>
                      ) : null}

                      <AvForm
                        className="form-horizontal"
                        onValidSubmit={(e, v) => handleValidSubmit(e, v)}
                      >
                        <div className="mb-3">
                          <AvField
                            name="email"
                            label="Email"
                            className="form-control"
                            placeholder=""
                            type="email"
                            disabled
                            value={email}
                            required
                          />
                        </div>
                        <div className="mb-3" style={{ position: "relative" }}>
                          <AvGroup>
                            <Label>New Password</Label>
                            <AvInput
                              name="password"
                              required
                              type={showPassword ? "text" : "password"}
                              placeholder="Enter New Password"
                              validate={{
                                minLength: {
                                  value: 6,
                                },
                              }}
                            />
                            <div
                              style={{
                                position: "absolute",
                                right: 30,
                                marginTop: "-30px",
                              }}
                            >
                              {showPassword ? (
                                <AiOutlineEyeInvisible
                                  size={20}
                                  className="text-muted"
                                  style={{ cursor: "pointer" }}
                                  onClick={handleShowPassword}
                                />
                              ) : (
                                <AiOutlineEye
                                  size={20}
                                  className="text-muted"
                                  style={{ cursor: "pointer" }}
                                  onClick={handleShowPassword}
                                />
                              )}
                            </div>
                            <AvFeedback>
                              New Password should be greater than 6 characters
                            </AvFeedback>
                          </AvGroup>
                        </div>
                        <div className="mb-3" style={{ position: "relative" }}>
                          <AvGroup>
                            <Label
                              className={`${
                                pwdMatch !== "" ? "text-danger" : ""
                              }`}
                            >
                              Confirm Password
                            </Label>
                            <AvInput
                              name="confirmPassword"
                              required
                              onChange={handleOnChange}
                              type={showConfirmPassword ? "text" : "password"}
                              placeholder="Enter Confirm Password"
                              className={`${
                                pwdMatch !== "" ? "border-danger" : ""
                              }`}
                            />
                            <div
                              style={{
                                position: "absolute",
                                right: 30,
                                marginTop: "-30px",
                              }}
                            >
                              {showConfirmPassword ? (
                                <AiOutlineEyeInvisible
                                  size={20}
                                  className="text-muted"
                                  style={{ cursor: "pointer" }}
                                  onClick={handleShowConfirmPassword}
                                />
                              ) : (
                                <AiOutlineEye
                                  size={20}
                                  className="text-muted"
                                  style={{ cursor: "pointer" }}
                                  onClick={handleShowConfirmPassword}
                                />
                              )}
                            </div>
                            <AvFeedback>Confirm Password required</AvFeedback>
                            <span
                              className="text-danger"
                              style={{ fontSize: "0.75rem" }}
                            >
                              {pwdMatch}
                            </span>
                          </AvGroup>
                        </div>
                        <Row className="row mb-0">
                          <Col className="col-12 text-end">
                            <button
                              className="btn btn-primary w-md waves-effect waves-light w-100"
                              type="submit"
                              disabled={props?.loading}
                            >
                              {props?.loading ? (
                                <div
                                  class="spinner-border spinner-border-sm text-light"
                                  role="status"
                                >
                                  <span class="sr-only">Loading...</span>
                                </div>
                              ) : (
                                "Set Password"
                              )}
                            </button>
                          </Col>
                        </Row>
                      </AvForm>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div class="card">
                      <h6
                        class="card-body text-center"
                        style={{
                          fontSize: "16px",
                          color: "darkred",
                          lineHeight: "20px",
                        }}
                      >
                        Hello, your set password link has expired please,
                        provide your email below to receive a new set password
                        link.
                      </h6>
                    </div>
                    <div style={{ marginTop: "-60px" }}>
                      <div className="p-0">
                        <AvForm
                          className="form-horizontal"
                          onValidSubmit={(e, v) => handleResendLink(e, v)}
                        >
                          {props.forgetError &&
                          typeof props.forgetError === "string" ? (
                            <Alert
                              color="danger"
                              className="text-center mb-4"
                              style={{ marginTop: "5px" }}
                            >
                              {props.forgetError}
                            </Alert>
                          ) : null}
                          {props.forgetSuccessMsg ? (
                            <Alert
                              color="success"
                              className="text-center mb-4"
                              style={{ marginTop: "13px" }}
                            >
                              {props.forgetSuccessMsg}
                            </Alert>
                          ) : null}
                          <div className="mb-3">
                            <AvField
                              name="email"
                              label="Email"
                              className="form-control"
                              placeholder=""
                              type="email"
                              required
                            />
                          </div>
                          <Row className="row mt-4">
                            <Col className="col-12 text-end">
                              <button
                                className="btn btn-primary w-md waves-effect waves-light w-100"
                                type="submit"
                              >
                                Resend Link
                              </button>
                            </Col>
                          </Row>
                        </AvForm>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

// state props
const mapStatetoProps = (state) => {
  const { error, loading } = state.SetPassword;
  const { forgetError, forgetSuccessMsg } = state.ForgetPassword;
  return { error, loading, forgetError, forgetSuccessMsg };
};

export default withRouter(
  connect(mapStatetoProps, { setPasswordAC, userForgetPassword })(
    SetPasswordPage
  )
);

SetPasswordPage.propTypes = {
  error: PropTypes.any,
  loading: PropTypes.any,
  history: PropTypes.object,
  setPasswordAC: PropTypes.func,
};
