export const TIMELINE_WEEKLY = "weekly";
export const TIMELINE_MONTHLY = "monthly";
export const TIMELINE_YEARLY = "yearly";

export const TIMELINES = [
  {
    label: "Weekly",
    value: TIMELINE_WEEKLY,
  },
  {
    label: "Monthly",
    value: TIMELINE_MONTHLY,
  },
  {
    label: "Yearly",
    value: TIMELINE_YEARLY,
  },
];
